import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import questions from "../../assets/img/questions.png";
import HelpModal from "common/HelpModal";
const ErrorPage = () => {
  const navigate = useNavigate();
  const [helpModal, setHelpModal] = React.useState(false);

  return (
    <>
      <h2 className="error-page-heading" style={{ fontWeight: "600" }}>
        Oops!
      </h2>
      <h6 className="error-page-text">
        {" "}
        We can't seem to find the page you're looking for. <br></br> We're
        launching a search party, but in the meantime try going back to the
        previous page or contact us for more information.
      </h6>
      <img
        src={"../assets/img/error-page.png"}
        alt=""
        className="error-picture"
      />
      <Button
        className="button-8559F3"
        style={{
          border: "none",
        }}
        onClick={() => {
          navigate("/dashboard");
        }}
      >
        Back to Dashboard
      </Button>
      <Button
        className="button-white"
        style={{
          width: "150px",
          color: "#473F4E",
          margin: "20px",
          borderColor: "#eeeeee",
        }}
        onClick={() => setHelpModal(true)}
      >
        <img
          src={questions}
          alt="questions"
          className="icon-size"
          style={{ marginRight: "8px" }}
        />{" "}
        Help
      </Button>
      {helpModal && (
        <HelpModal show={helpModal} onHide={() => setHelpModal(false)} />
      )}
    </>
  );
};

export default ErrorPage;
