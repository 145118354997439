import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import SuccessModal from "./SuccessModal";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { createCoupons, getCouponsDataById, logoUpload } from "redux/features/couponsAndCards/couponsAndCardsSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import STORAGEKEY from "config/APP/app.config";
import AuthStorage from "helper/AuthStorage";

const CreateCoupons = (props) => {
  const radioBtn = ["Coupon Card"];
  const [activeRadioBtn, setActiveRadioBtn] = useState("Coupon Card");
  const [imageFile, setImageFile] = useState("");
  const [imageSrc, setImageSrc] = useState();
  const [miniImage, setMiniImage] = useState({});
  const [openSuccessmodal, setOpenSuccessmodal] = useState(false);
  const [isEdit, setIsEdit] = useState(false)
  const [successMsg, setSuccessMsg] = useState("");
  const [editModalData, seteditModalData] = useState()
  const [data, setData] = useState({
    cg_name: "",
  });

  const couponsSchema = yup.object({
    top_description: yup.string().required("Name Is required."),
    body_text: yup.string().required("Card description Is required."),
    info_text: yup.string().required("Info text Is required."),
    text_under_mini_logo: yup.string().required("Mini logo text Is required."),
  });
  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
    resolver: yupResolver(couponsSchema),
  });

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const getEditCouponsData = async (id) => {
    await dispatch(getCouponsDataById(id)).then(unwrapResult).then((result) => {
      const returnData = result?.data?.[0]
      reset({
        top_description: returnData?.params?.top_description,
        body_text: returnData?.params?.body_text,
        info_text: returnData?.params?.info_text,
        text_under_mini_logo: returnData?.params?.text_under_mini_logo,
        mini: returnData?.params?.mini_logo_url,
        large: returnData?.params?.large_coupon_picture,
      })
      seteditModalData([...result?.data])
      return result?.data?.data
    }).catch((error) => {
      console.error(error);
    });
  }

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleRadioBtn = (item) => {
    setActiveRadioBtn(item);
  };

  const handleCouponsData = (data) => {
    const body = {
      visual_type: "start",
      price_points: 2,
      is_for_sale: true,
      category: activeRadioBtn,
      status: "active",
      params: {
        mini_logo_url: data.mini,
        large_coupon_picture: data.large,
        text_under_mini_logo: data.text_under_mini_logo,
        top_description: data.top_description,
        body_text: data.body_text,
        info_text: data.info_text
      },
      businessId: AuthStorage.getStorageData(STORAGEKEY.businessId),
    }
    if (activeRadioBtn === "Coupon Card") {
      isEdit ? dispatch(createCoupons({ ...body, id: editModalData?.[0]?.id })).then(unwrapResult)
        .then((res) => {
          if (res.code === 200) {
            setImageSrc("");
            setImageFile("");
            props.refreshList()
            props.onHideCoupons()

          }
        })
        .catch((err) => {
          console.log("err", err);
        }) : dispatch(createCoupons(body)).then(unwrapResult)
          .then((res) => {
            if (res.code === 200) {
              setImageSrc("");
              setImageFile("");
              props.refreshList()
              props.onHideCoupons()
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
    }
  }

  const goToListPage = () => {
    setOpenSuccessmodal(false);
    props.onHideCoupons();
    props.refreshList();
  };

  useEffect(() => {
    if (imageFile) {
      let src = URL.createObjectURL(imageFile);
      setImageSrc(src);
    }
  }, [imageFile]);

  useEffect(() => {
    props?.selectedId && setIsEdit(true)
  }, [props?.selectedId])

  useEffect(() => {
    if (props.type) {
      setActiveRadioBtn(props.type);
    }
  }, [props.type]);

  useEffect(() => {
    isEdit && getEditCouponsData(props?.selectedId)
  }, [isEdit])


  const filehandleChange = (file) => {
    setImageFile(file);
  };
  const handleCancel = () => {
    setImageFile("")
    setImageSrc("");
  }

  const imageUpload = (type, file) => {
    dispatch(logoUpload([type, file])).then(unwrapResult).then((res) => {
      setValue(type, res)
    }).catch((err) => {
      console.log(err);
    })
  }

  return (
    <>
      <Modal
        {...props}
        onHide={() => props.onHideCoupons()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="create-coupons-modals"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.showData ? t("cerateCoupon.heder") : t("cerateCoupon.edit_coupon")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {/* RADIO BUTTONS */}
          {props.showData && (
            <>
              <h2>{t("cerateCoupon.select_type")}</h2>
              <div className="create-coupons-Select-Type">
                {radioBtn.map((item) => (
                  <label
                    className={` ${activeRadioBtn === item && "bg-red"
                      } form-check`}
                    htmlFor={`rediobtn${item.id}`}
                    id="test1"
                    onClick={() => handleRadioBtn(item)}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      id={`rediobtn${item}`}
                      {...register('category')}
                      checked={activeRadioBtn === item ? true : false}
                    />
                    <label className="form-check-label" for={`rediobtn${item}`}>
                      {item}
                    </label>
                  </label>
                ))}
                {errors?.category && <p className='error_message'>{errors?.category?.message}</p>}
              </div>
            </>
          )}

          {/* CARD NAME */}
          <div className="activeinput">
            <label htmlFor="">{t("cerateCoupon.card_name")}</label>
            <input
              onChange={(e) => {
                handleChange(e);
              }}
              defaultValue={editModalData?.[0]?.params
                ?.top_description}
              {...register('top_description')}
              type="text"
              placeholder={t("cerateCoupon.card_name_placeholder")}


            />
            {errors?.top_description && <p className='error_message'>{errors?.top_description?.message}</p>}

            {/* CARD DESCRIPTION */}
            <label htmlFor="">{t("cerateCoupon.card_dsescription")}</label>
            <textarea
              onChange={(e) => {
                handleChange(e);
              }}
              {...register('body_text')}
              type="text"
              defaultValue={editModalData?.[0]?.params
                ?.body_text}
              placeholder={t("cerateCoupon.card_dsescriptio_placeholder")}
            />
            {errors?.body_text && <p className='error_message'>{errors?.body_text?.message}</p>}

            <label htmlFor="">{t("cerateCoupon.info_text")}</label>
            <textarea
              onChange={(e) => {
                handleChange(e);
              }}
              defaultValue={editModalData?.[0]?.params
                ?.info_text}
              {...register('info_text')}
              type="text"
              placeholder={t("cerateCoupon.info_text_palceholder")}
            />
            {errors?.info_text && <p className='error_message'>{errors?.info_text?.message}</p>}
          </div>

          {/* MINI LOGO TEXT */}
          <div className="activeinput">
            <label htmlFor="">{t("cerateCoupon.mini_logo_text")}</label>
            <input
              onChange={(e) => {
                handleChange(e);
              }}
              defaultValue={editModalData?.[0]?.params
                ?.text_under_mini_logo}
              {...register('text_under_mini_logo')}
              type="text"
              placeholder={t("cerateCoupon.mini_logo_text_placeholder")}
            />
            {errors?.text_under_mini_logo && <p className='error_message'>{errors?.text_under_mini_logo?.message}</p>}
          </div>
          {/* IMAGE UPLOAD */}

          {!imageSrc ? (
            <>
              <div
                className="upload-image"
              >
                <FileUploader
                  handleChange={(file) => {
                    filehandleChange(file);
                    imageUpload('large', file);
                  }}
                  {...register('large')}
                />
                <div className="upload-image-text">
                  <img src={"./assets/img/upload-image.png"} alt="" />
                  <h1>{t("cerateCoupon.upload_image")}</h1>
                  <h2>{t("cerateCoupon.image_formet")}</h2>
                </div>

              </div>

              {errors?.large && <p className='error_message'>{errors?.large?.message}</p>}
            </>
          ) : (
            <div className="upload-image">
              <div className="closeImg" onClick={handleCancel}>
                <img src="/assets/img/close.png" alt="close" />
              </div>
              <img src={imageSrc} alt="" width="100%" height="100%" className="img-preview" />
            </div>
          )}

          {/* MINI LOGO UPLOADER */}
          {!miniImage.fileUrl ? <><div
            className="upload-mini-image"
          >
            <FileUploader
              handleChange={(file) => {
                const url = URL.createObjectURL(file)
                setMiniImage({ fileUrl: url, file })

                imageUpload('mini', file);
              }}
              {...register('mini')}
            />
            <div className="upload-image-text">
              <img src={"./assets/img/upload-image.png"} alt="" className="img-preview" />
              <h2>{t("cerateCoupon.mini_logo_text")}</h2>
              <h2>{t("cerateCoupon.image_formet")}</h2>
            </div>
          </div>
            {errors?.mini && <p className='error_message'>{errors?.mini?.message}</p>}

          </>
            :
            <div className="upload-image">
              <div className="closeImg" onClick={() => {
                setMiniImage({})
              }}>
                <img src="/assets/img/close.png" alt="close" />
              </div>
              <img src={miniImage.fileUrl} alt="" width="100%" height="100%" className="img-preview" />
            </div>
          }

        </Modal.Body>
        <Modal.Footer>
          <a className="templat-img" onClick={props.showSelectImg}>
            {t("cerateCoupon.image_template")}
            <img src="./assets/img/right-arrow-color.png" alt="" className="" />
          </a>
          <div className="">
            <Button onClick={props.onHideCoupons} className="cancel-btn">
              {t("cerateCoupon.btn_cancel")}
            </Button>
            <Button
              type="button"
              className="submit-btn"
              onClick={handleSubmit((data) => {
                handleCouponsData(data)
              })}
            >
              {t("cerateCoupon.btn_sumbmit")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {openSuccessmodal && (
        <SuccessModal
          message={successMsg}
          show={openSuccessmodal}
          onHide={() => goToListPage()}
        />
      )}
    </>
  );
};

export default CreateCoupons;
