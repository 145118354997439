import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import connectArrow from "../../../assets/img/connect-your-e-store.png";
import platform_button_img from "../../../assets/img/platform_button.png";
import woo_img from "../../../assets/img/woocommerce-logo.png";
import fav from "../../../assets/img/fav-boomiyo.ico";
import other_platforms from "../../../assets/img/other_platforms.png";
import { ApiPost } from "helper/API/ApiData";
import { toast } from "react-toastify";
import AuthStorage from "helper/AuthStorage";
import STORAGEKEY from "config/APP/app.config";
import { BusinessBaseUrl } from "helper/API/BaseUrl";
import { useNavigate } from "react-router";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { TextField } from "@mui/material";
import { UserBaseUrl } from "helper/API/BaseUrl";
import ReactGA from "react-ga4";
import Close_button from "../../../assets/img/close_button.png";
import Approval from "../../../assets/img/Approval.svg";
import ApprovalGray from "../../../assets/img/ApprovalGray.png";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessDetails } from "redux/features/business/businessSlice";
import custon_integration_background from "../../../assets/img/custon_integration_background.png";

const ConnectYourEStore = ({ shopConnected, setShopConnected }) => {
  const [tab, setTab] = useState("platform");
  const [storeName, setStoreName] = useState(
    AuthStorage.getStorageData(STORAGEKEY.assumedShop) || ""
  );
  const [storeNameError, setStoreNameError] = useState(false);
  const [open, setOpen] = useState(false);
  const [openCustom, setOpenCustom] = useState(false);
  const [customSend, setCustomSend] = useState(false);
  const [eshopPlatform, setEshopPlatform] = useState('shopify');

  const [selectOption, setSelectOption] = useState();
  const data = useSelector((state) => state.authState.dashboardData.params);
  const dispatch = useDispatch();

  const handleConfirm = async (data) => {
    if (selectOption) {
      let body = {
        body: selectOption?.value ?? selectOption,
        email: data?.extra_fields?.email,
        title: "More Shop integrations",
      };

      await ApiPost(`${UserBaseUrl}help`, body).then((res) => {
        toast.success(
          "Your selection will help us prioritize integrations. Thank you for shaping the future of our platform!",
          {
            closeOnClick: true,
            pauseOnHover: true,
          }
        );
        setOpen(false);
      });
    }
  };

  const handleConfirmCustom = async (data) => {
    if (!customSend) {
      let body = {
        body: "Custom Integration Help",
        email: data?.extra_fields?.email,
        title: "Custom Integration",
      };

      await ApiPost(`${UserBaseUrl}help`, body).then((res) => {
        setCustomSend(true);
        toast.success(
          "We're on it! You will hear from us within 24 hours. Keep an eye on your inbox.",
          {
            closeOnClick: true,
            pauseOnHover: true,
          }
        );
        setOpen(false);
      });
    }
    handleCancelCustom();
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const handleCancelCustom = () => {
    setOpenCustom(false);
  };

  const handleOpen = () => {
    // setEshopPlatform('')
    ReactGA.event({
      action: "Start connecting others",
      category: "Connect your e-shop",
      value: "",
    });
    setOpen(true);
  };

  const handleWoo = () => {
    setEshopPlatform('woo')
    ReactGA.event({
      action: "Start connecting woo",
      category: "Connect your e-shop",
      value: "",
    });
    setTab("address");
  };

  const handleOpenCustom = () => {
    // setEshopPlatform('')
    ReactGA.event({
      action: "Start connecting others",
      category: "Connect your e-shop",
      value: "",
    });
    setOpenCustom(true);
  };

  const handleSetTab = () => {
    setEshopPlatform('shopify')
    ReactGA.event({
      action: "Start connecting shopify",
      category: "Connect your e-shop",
      value: "",
    });
    setTab("address");
  };

  const navigate = useNavigate();

  const handleVerify = () => {
    let status = "Not Installed";
    dispatch(getBusinessDetails()).then((result) => {
      if (result.payload.shopify_params.url) {
        setShopConnected(true);
      }
    });
  };

  const handleSubmit = () => {
    if (storeName !== "" && storeName !== null) {
      const body = {
        webshop_name: storeName,
      };
      ApiPost(`${BusinessBaseUrl}robot/generate-token?robotType=${eshopPlatform}`, body)
        .then((res) => {
          if (res && res.response && res.response.install_link) {
            ReactGA.event({
              action: `Lands on ${eshopPlatform} install app`,
              category: "Connect your e-shop",
              value: "",
            });

            window.open(res.response.install_link, "_blank");
          }
        })
        .catch((err) => {
          toast.error(err && err.response?.data?.metadata?.res_msg, {
            closeOnClick: true,
            pauseOnHover: true,
          });
        });
    } else {
      setStoreNameError(true);
    }

  };


  const shopOptions = [
    // { value: "WooCommerce", label: "WooCommerce" },
    { value: "WixStores", label: "Wix Stores" },
    { value: "Squarespace", label: "Squarespace" },
    { value: "Ecwid", label: "Ecwid" },
  ];
  return (
    <>
      <Modal
        show={open}
        onHide={handleCancel}
        backdrop="static"
        centered
        aria-labelledby="example-custom-modal-styling-title"
        className="initial_add_store_modal"
        style={{
          width: "100% !important",
          zIndex: 9999,
        }}
      >
        <Modal.Body className="plansModal">
          <img
            onClick={handleCancel}
            src={Close_button}
            className="closeIcon icon-size"
            alt="close"
          />
          <div
            className="initial_add_store_main"
            style={{ flexDirection: "column" }}
          >
            <h1
              className="header"
              style={{ marginBottom: "40px", textAlign: "center" }}
            >
              Choose Your Platform
            </h1>
            <p
              style={{
                marginBottom: "40px",
                fontWeight: 600,
                textAlign: "start",
              }}
            >
              Exciting news! We're expanding Boomio's integration. Choose from
              the list below or enter another platform:
            </p>
            <p style={{ marginBottom: "20px", textAlign: "start" }}>
              Choose a platform for Boomio integration.
            </p>
            <Select
              name="colors"
              options={shopOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select shop"
              value={selectOption}
              onChange={(value) => setSelectOption(value)}
              styles={{
                valueContainer: (base) => ({
                  ...base,
                  height: "56px",
                  borderRadius: "16px",
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
            />
            <p style={{ margin: "20px 0px", textAlign: "start" }}>
              Or enter the name of another platform
            </p>
            <TextField
              onChange={(e) => setSelectOption(e.target.value)}
              placeholder="Shop name"
              style={{
                border: "1px solid hsl(0, 0%, 80%)",
                padding: "0px",
                borderRadius: "4    px",
                width: "100%",
                margin: "0px 0px 40px 0px",
              }}
            />
            <div style={{ flexDirection: "row", width: "100%" }}>
              <Button
                onClick={handleCancel}
                className="button-8559F3 boomio_input boomio_input_main mx-3"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                className="button-8559F3 boomio-white mx-3"
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={openCustom}
        onHide={handleCancelCustom}
        backdrop="static"
        centered
        aria-labelledby="example-custom-modal-styling-title"
        className="initial_add_store_modal"
        style={{
          width: "100% !important",
          zIndex: 9999,
        }}
      >
        <Modal.Body className="campaignPlansModal">
          <img
            onClick={handleCancelCustom}
            src={Close_button}
            className="closeIcon icon-size"
            alt="close"
          />
          <div
            className="initial_add_store_main"
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "0px 40px",
            }}
          >
            <h1
              className="campaign-header"
              style={{
                marginBottom: "30px",
                textAlign: "center",
                fontSize: "24px",
                margin: "0px 30px 30px 30px",
              }}
            >
              THANK YOU FOR CHOOSING BOOMIO CUSTOM INTEGRATION!
            </h1>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p className="custom-integration-text">
                We're on it! Click the button below and integration instructions
                will be sent to email <b>{data?.extra_fields?.email}</b>. Our
                dedicated representative will contact you within 24 hours for
                further assistance.
              </p>
            </div>
            <div
              style={{ flexDirection: "row", width: "100%", marginTop: "20px" }}
            >
              <Button
                onClick={handleConfirmCustom}
                className="outline_btn_dark_campagin launch_btn custom_integration_btn"
              >
                <span> SEND INTEGRATION INSTRUCTIONS</span>
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <p>
        Select a platform of your online store and follow other steps to
        continue.
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "space-between",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <div
          onClick={handleSetTab}
          style={{ marginRight: "47px", marginBottom: "12px" }}
          // className={ "shopify-paper shopify-paper-color"}
          className={(eshopPlatform === 'shopify') ? "shopify-paper shopify-paper-color" : "shopify-paper"}
        >
               <div className={`checkbox-input-body${(eshopPlatform === 'shopify') ? '-checked' : ''} inside-card`}></div>
          <img src={platform_button_img} alt="" />
        </div>

        <div
          onClick={handleWoo}
          style={{
            marginRight: "47px", marginBottom: "12px",
            backgroundSize: "cover",
            height: "125px",
            backgroundPosition: "center",
            marginRight: "47px",
            marginBottom: "12px",
          }}
          className={(eshopPlatform === 'woo') ? "shopify-paper shopify-paper-color" : "shopify-paper"}
        >
                  <div className={`checkbox-input-body${(eshopPlatform === 'woo') ? '-checked' : ''} inside-card`}></div>
          <img style={{ width: '100%' }} src={woo_img} alt="" />
        </div>


        <div
          className="platform_conntent wix_platform"
          onClick={handleOpen}
          style={{
            backgroundImage: `url(${other_platforms})`,
            backgroundSize: "cover",
            height: "125px",
            backgroundPosition: "center",
            marginRight: "47px",
            marginBottom: "12px",
          }}
        >
          <p style={{ zIndex: 2, fontSize: "20px", fontWeight: 500 }}>
            OTHER PLATFORMS
          </p>
        </div>

        <div
          className="platform_conntent"
          onClick={handleOpenCustom}
          style={{
            backgroundImage: `url(${custon_integration_background})`,
            backgroundSize: "cover",
            height: "125px",
            backgroundPosition: "center",
          }}
        >
          {/* <p style={{ zIndex: 2, fontSize: "20px", fontWeight: 500 }}>
            CUSTOM INTEGRATIONS
          </p> */}
        </div>
      </div>
      <>
        <div className="w-100 mt-4">
          {eshopPlatform !== '' && <p>Enter your store adress and</p>}
          <div
            style={{
              textAlign: "end",
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              flexWrap: "wrap",
            }}
          >
            <div>
              {eshopPlatform === 'shopify' && <div
                className="e_store_address_input_main"
                style={{ marginTop: "20px" }}
              >
                <p className="e_store_address_span_start">
                  <p
                    className="shop-url-text"
                    style={{
                      cursor: "pointer !important",
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    https://{" "}
                  </p>
                </p>
                <input
                  type="text"
                  className="e_store_address_input shop-url-text"
                  placeholder="Your e-shop name"
                  value={storeName}
                  onChange={(e) => setStoreName(e.target.value)}
                  name="storeName"
                />
                <p className="e_store_address_span_end">
                  <p
                    className="shop-url-text"
                    style={{
                      cursor: "pointer !important",
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    .myshopify.com{" "}
                  </p>
                </p>
              </div>}
              {eshopPlatform === 'woo' && <div
                className="e_store_address_input_main"
                style={{ marginTop: "20px" }}
              >
                <p className="e_store_address_span_start">
                  <p
                    className="shop-url-text"
                    style={{
                      cursor: "pointer !important",
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    https://{" "}
                  </p>
                </p>
                <input
                  type="text"
                  className="e_store_address_input shop-url-text-full-url"
                  placeholder="Your e-shop url"
                  value={storeName}
                  onChange={(e) => setStoreName(e.target.value)}
                  name="storeName"
                />
                {/* <p className="e_store_address_span_end">
              <p
                className="shop-url-text"
                style={{
                  cursor: "pointer !important",
                  alignItems: "center",
                  fontSize: "14px",
                  fontWeight: "400",
                  marginBottom: "0px",
                }}
              >
                .myshopify.com{" "}
              </p>
            </p> */}
              </div>}
              {storeNameError && (
                <Form.Text className="color e_store_address_error ">
                  E-shop name required
                </Form.Text>
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <Button
                disabled={shopConnected}
                className={`${shopConnected
                  ? "disabled_button space_button"
                  : "outline_btn_connect space_button"
                  }`}
                onClick={handleSubmit}
                type="submit"
                style={{
                  minWidth: "180px",
                  marginTop: "20px",

                  background:
                    "linear-gradient(90deg, rgba(254, 227, 233, 0.40) 0%, rgba(255, 215, 232, 0.40) 21.88%, rgba(243, 219, 240, 0.40) 41.67%, rgba(234, 223, 247, 0.40) 62.50%, rgba(234, 223, 247, 0.40) 81.77%, rgba(238, 216, 255, 0.40) 100%)",
                }}
              >
                <span>{shopConnected ? "CONNECTED" : "CONNECT SHOP"}</span>
              </Button>
              {!shopConnected ? (
                <>
                  <div
                    className="shop-verify"
                    style={{
                      width: "120px",
                      cursor: "pointer",
                      marginTop: "20px",
                    }}
                    onClick={handleVerify}
                  >
                    CLICK TO VERIFY
                  </div>
                  <img
                    style={{ marginLeft: "10px", marginTop: "18px" }}
                    src={ApprovalGray}
                  ></img>
                </>
              ) : (
                <>
                  <div
                    className="shop-verify"
                    style={{
                      width: "120px",
                      color: "#15D1D3",
                      marginTop: "20px",
                    }}
                  >
                    VERIFIED
                  </div>
                  <img
                    style={{
                      marginLeft: "10px",
                      color: "#15D1D3",
                      marginTop: "20px",
                    }}
                    src={Approval}
                  ></img>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default ConnectYourEStore;
