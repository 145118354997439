import React from "react";
import ReactApexChart from "react-apexcharts";

const Splinegraph = ({ options, series, height, subTitle, title }) => {
  return (
    <>
      <div className="graph_title">
        <h1 className="title">{title}</h1>
        <div className="sub-title">
          <img
            src="./assets/img/graph_sub_title.png"
            alt=""
            className="icon-size"
          />
          <p>{subTitle}</p>
        </div>
      </div>
      <div id="chart" className="bg-white splinegraph">
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={height}
        />
      </div>
    </>
  );
};

export default Splinegraph;
