import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { getCustomers } from "redux/features/customers/customersSlice";
import AddCustomer from "../componets/modals/AddCustomer";
import DeleteModal from "../componets/modals/DeleteModal";
import ImportCustomer from "../componets/modals/ImportCustomer";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import Header from "layout/header/Header";
import moment from "moment";

const Customers = () => {
  const dispatch = useDispatch();

  const [modalShow, setModalShow] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [modelType, setModelType] = useState();
  const [dropdownmanu, setdropdownmanu] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  // const [totalCount, setTotalCount] = useState(0);
  const [customersData, setCustomersData] = useState([]);
  const [sortBy, setSortBy] = useState("created_at");
  const [sortDirection, setSortDirection] = useState(-1);
  const [isChecked, setIsChecked] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [currentCount, setCurrentCount] = useState(1);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [apiOrder, setApiOrder] = useState(-1);
  const [totalCount, setTotalCount] = useState(0);

  const [checkboxes, setCheckboxes] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const openMenu = (i) => {
    if (i === dropdownmanu) {
      setdropdownmanu();
    } else {
      setdropdownmanu(i);
    }
  };
  const nextPage = () => {
    const nextpage = pageNumber + 1;
    if (nextpage <= totalPages) {
      getCampaignsData(nextpage, perPage, apiOrder);
      setPageNumber(nextpage);
    } else {
      getCampaignsData(pageNumber, perPage, apiOrder);
    }
  };
  const previousPage = () => {
    const nextpage = pageNumber - 1;
    if (nextpage > 0) {
      getCampaignsData(nextpage, perPage, apiOrder);
      setPageNumber(nextpage);
      setCurrentCount(nextpage);
    } else {
      getCampaignsData(pageNumber, perPage, apiOrder);
    }
  };

  const getCampaignsData = async (pageNumber, limit, orderBy) => {
    await dispatch(getCustomers([pageNumber, limit, orderBy]))
      .then(unwrapResult)
      .then((result) => {
        if (result && result.response) {
          if (result && result.response) {
            setCustomersData(result?.response);
          }
          if (result?.response?.count) {
            setTotalCount(result.response.count);
          }
        }
        // setTotalCount(result?.response?.count);
        return result?.response;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getCampaignsData(0, perPage, apiOrder);
    return () => setCustomersData([]);
  }, []);

  const handleChange = (ind) => {
    let checkboxesCopy = [...checkboxes];
    checkboxesCopy[ind] = !checkboxes[ind];
    setCheckboxes([...checkboxesCopy]);
  };

  const handleChangeAll = (val) => {
    let checkboxesCopy = [...checkboxes];
    checkboxesCopy.forEach((e, i) => (checkboxesCopy[i] = !val));
    setCheckboxes([...checkboxesCopy]);
  };

  const capFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    const totalPageRounded = Math.floor(Math.round(totalCount / perPage));
    const totalPage = totalCount / perPage;
    let pages;
    if (totalPage > totalPageRounded) {
      pages = totalPageRounded + 1;
      setTotalPages(pages);
    } else {
      pages = totalPageRounded;
      setTotalPages(pages);
    }
  }, [totalCount, perPage]);
  return (
    <>
      <Header title={"Customers"} />
      <div className="customer-table">
        <div className="table-bg">
          <div className="table-bg-inner">
            <div className="table-scroll">
              <div style={{ width: "2000px" }}>
                <table className=" overflow-x-auto position-relative">
                  <thead className="thead-bg">
                    <tr>
                      <th scope="col">
                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            value="above"
                            name="checkbox"
                            checked={isChecked}
                            onChange={() => {
                              handleChangeAll(isChecked);
                              setIsChecked((prev) => !prev);
                            }}
                            className="checkbox-input"
                          />
                          <span
                            onClick={() => {
                              setSortBy("campaign_name");
                              setSortDirection(sortDirection === 1 ? -1 : 1);
                            }}
                            className="headr-text"
                          >
                            Campaign type
                          </span>
                          <img
                            src={"./assets/img/header-icon.png"}
                            alt=""
                            className="icon-6"
                            onClick={() => {
                              setSortBy("campaign_name");
                              setSortDirection(sortDirection === 1 ? -1 : 1);
                            }}
                          />
                        </div>
                      </th>
                      <th scope="col">
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            setSortBy("campaign_name");
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">Campaign Name</span>
                          <img
                            src="./assets/img/header-icon.png"
                            className="icon-6"
                            alt=""
                          />
                        </div>
                      </th>

                      <th scope="col">
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            setSortBy("user_since");
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">User Since</span>
                          <img
                            src="./assets/img/header-icon.png"
                            className="icon-6"
                            alt=""
                          />
                        </div>
                      </th>
                      <th scope="col">
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            setSortBy("email");
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">Email</span>
                          <img
                            src="./assets/img/header-icon.png"
                            className="icon-6"
                            alt=""
                          />
                        </div>
                      </th>
                      <th scope="col">
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            setSortBy("has_consumed_coupon");
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">Coupon Used</span>
                          <img
                            src="./assets/img/header-icon.png"
                            className="icon-6"
                            alt=""
                          />
                        </div>
                      </th>
                      <th scope="col">
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            setSortBy("Source");
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">Source</span>
                          <img
                            src="./assets/img/header-icon.png"
                            className="icon-6"
                            alt=""
                          />
                        </div>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {customersData?.result?.length > 0 ? (
                      customersData?.result
                        .sort((a, b) =>
                          a[sortBy] > b[sortBy]
                            ? sortDirection
                            : -1 * sortDirection
                        )
                        .map((item, i) => {
                          return (
                            <tr key={item.id}>
                              <td>
                                <input
                                  type="checkbox"
                                  value="above"
                                  name="checkbox"
                                  className="checkbox-input-body"
                                  onChange={() => handleChange(i)}
                                  checked={checkboxes[i]}
                                  id={i}
                                />
                                <span className="headr-text">
                                  {item.email
                                    ? "Generate leads"
                                    : "Boost your products"}
                                </span>
                              </td>
                              <td>
                                <span className="headr-text">
                                  {item.campaign_name}
                                </span>
                              </td>
                              <td>
                                <span className="headr-text status">
                                  {moment(item?.user_since).format(
                                    "DD/MM/YYYY"
                                  )}
                                </span>
                              </td>
                              <td>
                                <span className="headr-text">{item.email}</span>
                              </td>

                              <td>
                                <span className="headr-text">
                                  {item.has_consumed_coupon ? "Yes" : "No"}
                                </span>
                              </td>
                              <td>
                                <span className="headr-text">
                                  {item.source}
                                </span>
                              </td>

                              <td>
                                <div className="d-flex justify-content-between position-relative">
                                  <button
                                    onClick={() => {
                                      openMenu(i);
                                    }}
                                    className="three-dot-button"
                                  >
                                    <img
                                      src="./assets/img/three-dot.png"
                                      alt=""
                                      height="18px"
                                    />
                                  </button>
                                  {dropdownmanu === i && (
                                    <div className="dropdownmanu">
                                      <Button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setModalShow(true);
                                          setModelType("edit");
                                          setdropdownmanu();
                                        }}
                                      >
                                        {" "}
                                        <img
                                          src="./assets/img/Edit.png"
                                          alt=""
                                          className="me-2"
                                        />
                                        Edit
                                      </Button>
                                      <Button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setDeleteModal(true);
                                          setdropdownmanu();
                                        }}
                                      >
                                        <img
                                          src="./assets/img/delete-icon.png"
                                          alt=""
                                          className="me-2"
                                        />
                                        Delete
                                      </Button>
                                      <Button>
                                        {" "}
                                        <img
                                          src="./assets/img/downlaod-icon.png"
                                          alt=""
                                          className="me-2"
                                        />{" "}
                                        Download
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                    ) : (
                      <tr>
                        <td colSpan="7">{t("noDataFound")}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="pagination mt-4 d-flex justify-content-between">
            <div className="records-per-page">
              <span className="boomio-bold purple-black">
                {capFirst(t("campaign.table.show"))}
              </span>
              <select
                className="perpage"
                name="perpage"
                id=""
                value={perPage}
                onChange={(e) => {
                  setPerPage(e.target.value);
                  setCurrentCount(1);
                  setPageNumber(0);
                }}
              >
                <option value={10}>{t("campaign.table.10")}</option>
                <option value={20}>{t("campaign.table.20")}</option>
                <option value={50}>{t("campaign.table.50")}</option>
                <option value={100}>{t("campaign.table.100")}</option>
              </select>
              <span className="boomio-bold purple-black">
                {t("campaign.table.entries")}
              </span>
            </div>
            <div className="go-to-page">
              <span className="boomio-bold purple-black">
                {currentCount} {t("campaign.table.of")} {totalPages}
              </span>
              <button
                disabled={currentCount === 1}
                style={{ opacity: currentCount === 1 ? "0.5" : "1" }}
                className="paginationBtn"
                onClick={previousPage}
              >
                <img src="./assets/img/table-left-arrow.png" alt="" />
              </button>
              <button
                className="paginationBtn"
                style={{ opacity: currentCount === totalPages ? "0.5" : "1" }}
                disabled={currentCount === totalPages}
                onClick={nextPage}
              >
                <img src="./assets/img/table-right-arrow.png" alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {modalShow && (
        <AddCustomer
          type={modelType}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
      {importModal && (
        <ImportCustomer
          show={importModal}
          onHide={() => setImportModal(false)}
        />
      )}
    </>
  );
};

export default Customers;
