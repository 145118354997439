import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiGet } from "helper/API/ApiData";
import { BusinessBaseUrl } from "helper/API/BaseUrl";

export const getCustomers = createAsyncThunk(
  "campaigns/getCampaigns",
  async (data, thunkAPI) => {
    const page = data?.[0];
    const limit = data?.[1];
    return await ApiGet(
      `${BusinessBaseUrl}business/get/customers/?page=${page}&limit=${limit}`
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        err = { isError: true };
        return err;
      });
  }
);

export const customersSlice = createSlice({
  name: "campaignSlice",
  initialState: { entities: [], loading: false },
  reducers: {},
  extraReducers: {
    [getCustomers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.value += action.payload;
    },
    [getCustomers.reject]: (state, action) => {
      state.isLoading = false;
    },
    [getCustomers.pending]: (state, action) => {
      state.isLoading = true;
    },
  },
});

export default customersSlice.reducer;
