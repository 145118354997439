import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import SuccessModal from '../../componets/modals/SuccessModal';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import queryString from 'query-string';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateRangePicker } from 'materialui-daterange-picker';
import datepicker from "../../../assets/img/date-picker.png";
import { useDispatch } from 'react-redux';
import { createCampaigns, editCampaigns, getCampaignsById } from 'redux/features/campaigns/campaignSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { MenuItem, Select } from '@mui/material';
import { getAllCouponsData } from 'redux/features/couponsAndCards/couponsAndCardsSlice';
import DiscountModal from '../widgets/DiscountModal';
import STORAGEKEY from 'config/APP/app.config';
import AuthStorage from 'helper/AuthStorage';
import { CouponsBaseUrl } from 'helper/API/BaseUrl';

const ProductDesign = ({ type }) => {
    const [modalShow, setModalShow] = useState(false);
    const query = queryString.parse(window.location.search);
    const id = query?.id
    const [open, setOpen] = React.useState(false);
    const [dateRange, setDateRange] = React.useState({});
    const [campaignData, setCampaignData] = useState()
    const [isActive, setIsActive] = useState(true)
    const [couponsData, setCouponsData] = useState([])
    const [couponCode, setCouponCode] = useState("")
    const [couponData, setCouponData] = useState()
    const [data, setData] = useState({
        name: "",
        start_date: campaignData?.start_date,
        end_date: campaignData?.end_date,
        cg_id: ""
    });
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        getCouponsData()
    }, [])

    const getCouponsData = async () => {
        await dispatch(getAllCouponsData()).then(unwrapResult).then((result) => {
            setCouponsData(result?.data)
            return result?.data?.data
        }).catch((error) => {
            console.error(error);
        });
    }

    const campaignSchema = yup.object({
        name: yup.string().required("Name Is required."),
        start_date: yup.string().required("Please select Campaign period."),
        description: yup.string().required("Description Is required."),
        campaignTitle: yup.string().required("Campaign Title Is required."),
        coupon_code: yup.string().required("Please select one Coupon."),
        amount_threshold: type === 'CARD_PAYMENTS' && yup.string().required("This Field Is required."),
    });
    const { register, handleSubmit, setValue, reset, formState: { errors }, clearErrors } = useForm({
        resolver: yupResolver(campaignSchema),
    });

    const getCampaignsData = async () => {
        await dispatch(getCampaignsById(id)).then(unwrapResult).then((res) => {
            setIsActive(res?.data?.status === 'active' ? true : false)
            setCouponCode(res?.data?.params?.coupon_visual_id)
            setData(prev => (
                {
                    ...prev,
                    start_date: moment(res?.data?.start_date).format("YYYY/MM/DD"),
                    end_date: moment(res?.data?.end_date).format("YYYY/MM/DD")
                }))
            setCampaignData(res?.data)
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        reset({
            name: campaignData?.name,
            description: campaignData?.description,
            title: campaignData?.campaignTitle,
            amount_threshold: campaignData?.amount_threshold,
            start_date: campaignData?.start_date,
            end_date: campaignData?.end_date,
        })
    }, [campaignData])

    useEffect(() => {
        if (id) {
            getCampaignsData()
        }
    }, [id])

    useEffect(() => {
        setData({
            ...data,
            start_date: moment(dateRange.startDate).format("YYYY/MM/DD"),
            end_date: moment(dateRange.endDate).format("YYYY/MM/DD")
        })
    }, [dateRange]);



    const handleCampaignData = (payload) => {
        const returnData = {
            name: payload?.name,
            description: payload?.description,
            title: payload?.campaignTitle,
            amount_threshold: payload?.amount_threshold,
            code: "abcd",
            status: isActive === true ? 'active' : 'inactive',
            start_date: payload?.start_date,
            end_date: payload?.end_date,
            params: {
                coupon_visual_id: payload.coupon_code
            },
            scan_allowed: "any",
            win_button_action: "winbutn",
            max_scan: 10,
            max_scan_unit: "days",
            business_id: AuthStorage.getStorageData(STORAGEKEY.businessId),
            author_u_id: 2,
            modified_u_id: 3,
            campaign_payment_type: type,
            campaign_type: type
        }
        dispatch(id ? editCampaigns({ id, ...returnData }) : createCampaigns(returnData))
        setModalShow(true)
    }

    const toggle = () => { setOpen(!open) };

    const goToListPage = () => {
        setModalShow(false)
        navigate('/campaigns')
    }

    return (
        <>
            <div className='campaigns-heade'>
                <h1 className='page-header'>{type === "CARD_PAYMENTS" ? "Card Payment" : "Scan Code"}</h1>
            </div>
            <div className='d-xl-flex'>
                <div className='product-design-bg'>
                    <h2>{type === "CARD_PAYMENTS" ? "Card Payment" : "Scan Code"}</h2>
                    <h3>{type === "CARD_PAYMENTS" ? "Enter Campaign Details" : "Enter promotional details"}</h3>
                    <div className='d-sm-flex justify-content-between'>
                        <div className='activeinput w-45'>
                            <label htmlFor="" className='label-text'>Campaign Name *</label>
                            <input type="text" defaultValue={campaignData?.name} {...register('name')} placeholder='My first timer 20% discount campaign' />
                            {errors?.name && <p className='error_message'>{errors?.name?.message}</p>}
                        </div>

                        <div className='set-date-picker first-time-reward w-45' >
                            <label htmlFor="" className='label-text'>Campaign Period *</label>
                            <div className="date-picker mt-7" style={{ position: "relative" }} {...register('start_date')} {...register('end_date')} onClick={() => setOpen(!open)}>
                                {data.start_date ? data.start_date : "Start Date"} {data.end_date ? " - " + data.end_date : " - End Date"}
                                <img src={datepicker} alt="" onClick={() => setOpen(!open)} />
                            </div>
                            <DateRangePicker
                                open={open}
                                toggle={toggle}
                                onChange={(range) => {
                                    clearErrors('start_date');
                                    setValue('start_date', moment(range.startDate).format("YYYY/MM/DD"))
                                    setValue('end_date', moment(range.endDate).format("YYYY/MM/DD"))
                                    setDateRange(range)
                                }}
                            />
                            {errors?.start_date && <p className='error_message'>{errors?.start_date?.message}</p>}
                        </div>
                    </div>
                    <div className='activeinput w-100'>
                        <label htmlFor="" className='label-text'>Campaign Title </label>
                        <input type="text" defaultValue={campaignData?.title} {...register('campaignTitle')} placeholder='Get your 20% discount!' />
                        {errors?.campaignTitle && <p className='error_message'>{errors?.campaignTitle?.message}</p>}
                        <label htmlFor="" className='label-text'>Description *</label>
                        <textarea type="text" defaultValue={campaignData?.description} {...register('description')} placeholder='With this coupon you can get 20% discount of a fresh orange juice size of 0,33L.' />
                        {errors?.description && <p className='error_message'>{errors?.description?.message}</p>}
                        {type === 'CARD_PAYMENTS' && <>
                            <label htmlFor="" style={{ display: "flex" }} className='label-text'>Give user prize when he makes payment more than  <input style={{ width: "auto", padding: "6px 10px", margin: "auto 5px" }} type="text" {...register('amount_threshold')} placeholder='Input digital curr' /> Eur </label>
                            {errors?.amount_threshold && <p className='error_message'>{errors?.amount_threshold?.message}</p>}
                        </>}
                    </div>
                    {campaignData?.status === 'active' && <>
                        <div className='img-template gap-3'>
                            <label className='campaign-download-btn' >Download Your QR Codes to put in/on Delivery Boxes in Text/CVS Format </label>
                            <a href={`${CouponsBaseUrl}barcode_csv?campaignId=${id}`} style={{ textDecoration: "none" }}><Button className=''>Download</Button></a>
                        </div>
                        <div className='img-template gap-3'>
                            <label className='campaign-download-btn'>Download Your QR Codes to put in/on Delivery Boxes in ready to print PNG Format  </label>
                            <a href={`${CouponsBaseUrl}barcode_download?campaignId=${id}`} style={{ textDecoration: "none" }}><Button className=''>Download</Button></a>
                        </div>
                    </>}
                    <div className='img-template'>
                        <div className='switch-main mt-3'>
                            <label className="switch me-3">
                                <input type="checkbox" onChange={(e) => setIsActive(!isActive)} checked={isActive} />
                                <span className="slider round"></span>
                            </label>
                            <p>Active</p>
                        </div>
                    </div>
                    <div className='product-design-bg-2 Preview-select'>
                        <label htmlFor="" className='label-text mb-2'>Coupon Prize</label>
                        <Select
                            style={{ width: "100%", padding: '0px' }}
                            value={`${couponCode}`}
                            displayEmpty
                            {...register('coupon_code')}
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={(e) => {
                                setCouponCode(e.target.value);
                                clearErrors('coupon_code');
                            }}
                        >
                            {couponsData.map((ele) => (
                                <MenuItem
                                    key={ele.id}
                                    value={ele.id} sx={{ gap: '20px' }}
                                    onClick={() => {
                                        setCouponData(ele)
                                    }} >
                                    {ele.params?.mini_logo_url ? <img alt='coupons logo' style={{ width: "30px", height: "30px", borderRadius: '3px', marginRight: '10px' }} src={ele.params?.mini_logo_url}></img> :
                                        <img src={'../../../assets/img/br.png'} alt='coupons logo' style={{ width: "30px", height: "30px", opacity: '0.2', borderRadius: '3px', marginRight: '10px' }} />}
                                    {ele.params?.top_description}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors?.coupon_code && <p className='error_message'>{errors?.coupon_code?.message}</p>}
                    </div>

                    <div className='start-promotional-campaign-footer'>
                        <Button onClick={() =>
                            navigate(id
                                ? '/campaigns' :
                                "/campaigns/start-campaign"
                            )} className='cancel-btn'>Back</Button>
                        <Button onClick={handleSubmit((data) => {
                            handleCampaignData(data)
                        })} className='submit-btn'>{id ? "Update" : "Launch"}</Button>
                    </div>

                </div>
                <DiscountModal couponData={couponData} />
            </div>
            {
                modalShow && <SuccessModal show={modalShow} onHide={() => goToListPage()} />
            }
        </>
    );
};

export default ProductDesign;
