import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup'
import { DateRangePicker } from 'materialui-daterange-picker';
import moment from 'moment';
import datepicker from "../../../assets/img/date-picker.png";
import { useDispatch } from 'react-redux';
import { createCampaigns, editCampaigns, getCampaignsById } from 'redux/features/campaigns/campaignSlice';
import SuccessModal from 'pages/componets/modals/SuccessModal';
import { Switch } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import queryString from 'query-string';

const VisitMyShop = () => {
    const dispatch = useDispatch()
    const query = queryString.parse(window.location.search);
    const id = query?.id
    const [open, setOpen] = React.useState(false);
    const [dateRange, setDateRange] = React.useState({});
    const [checkbox, setCheckbox] = useState([])
    const [campaignData, setCampaignData] = useState()
    const [activeRadioBtn, setActiveRadioBtn] = useState()
    const [activeRadioBtn2, setActiveRadioBtn2] = useState()
    const [activeRadioBtn3, setActiveRadioBtn3] = useState()
    const [isActive, setIsActive] = useState(false)
    const [data, setData] = useState({
        name: "",
        start_date: campaignData?.start_date ? campaignData?.start_date : "",
        end_date: campaignData?.end_date ? campaignData?.end_date : "",
        cg_id: ""
    });
    const [modalShow, setModalShow] = React.useState(false);
    const navigate = useNavigate()

    const campaignDataSchema = yup.object({
        name: yup.string().required("Name Is required."),
        start_date: yup.string().required("This field Is required."),
        customer_to_send: yup.string().required("This field Is required.").nullable(),
        when_to_launch: yup.string().required("This field Is required.").nullable(),
        camp_sent_to_customer: yup.array().required("This field Is required.").nullable(),
        frequency: yup.string().required("This field Is required.").nullable(),
    });
    const { register, handleSubmit, setValue, reset, formState: { errors }, clearErrors } = useForm({
        resolver: yupResolver(campaignDataSchema),
    });

    const handleRadioBtn = (id) => {
        setActiveRadioBtn(id)
    }
    const handleRadioBtn2 = (id) => {
        setActiveRadioBtn2(id)
    }
    const handleRadioBtn3 = (id) => {
        setActiveRadioBtn3(id)
    }

    const getCampaignsData = async () => {
        await dispatch(getCampaignsById(id)).then(unwrapResult).then((res) => {
            setIsActive(res?.data?.status === 'active' ? true : false)
            setCheckbox(res?.data?.camp_sent_to_customer)
            setActiveRadioBtn(res?.data?.frequency)
            setActiveRadioBtn2(res?.data?.customer_to_send)
            setActiveRadioBtn3(res?.data?.when_to_launch)
            setData(prev => (
                {
                    ...prev,
                    start_date: moment(res?.data?.start_date).format("YYYY/MM/DD"),
                    end_date: moment(res?.data?.end_date).format("YYYY/MM/DD")
                }))
            setCampaignData(res?.data)
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        setData({
            ...data,
            start_date: moment(dateRange.startDate).format("YYYY/MM/DD"),
            end_date: moment(dateRange.endDate).format("YYYY/MM/DD")
        })
    }, [dateRange]);

    useEffect(() => {
        if (id) {
            getCampaignsData()
        }
    }, [id])

    useEffect(() => {
        reset({
            name: campaignData?.name,
            camp_sent_to_customer: campaignData?.camp_sent_to_customer,
            customer_to_send: campaignData?.customer_to_send,
            when_to_launch: campaignData?.when_to_launch,
            start_date: campaignData?.start_date,
            end_date: campaignData?.end_date,
            frequency: campaignData?.frequency,
        })
    }, [campaignData])

    useEffect(() => {
        checkbox.length > 0 && clearErrors('camp_sent_to_customer');
    }, [checkbox])

    const toggle = () => { setOpen(!open) };

    const handlecheckbox = (id) => {
        if (checkbox.find((a) => a === id)) {
            setCheckbox(checkbox.filter((fil) => fil !== id))
        } else {
            setCheckbox([...checkbox, id])
        }
    }

    const handleCampaignData = (data) => {
        const returnData = {
            name: data?.name,
            description: data?.description,
            title: data?.campaignTitle,
            camp_sent_to_customer: data?.camp_sent_to_customer,
            customer_to_send: data?.customer_to_send,
            when_to_launch: data?.when_to_launch,
            status: isActive === true ? 'active' : 'inactive',
            start_date: data?.start_date,
            end_date: data?.end_date,
            frequency: data?.frequency,
            campaign_payment_type: "CARD_PAYMENTS",
            campaign_type: "visitMyShop",
        }
        dispatch(id ? editCampaigns({ id, ...returnData }) : createCampaigns(returnData))
        setModalShow(true)
    }

    const goToListPage = () => {
        setModalShow(false)
        navigate('/campaigns')
    }

    const radioBtn = ["Per Day", "Per Week", "Per Month", "Per Campaign"]
    const radioBtn2 = ["All", "New", "Active", "Lapsed"]
    const CheckBoxBtn = ["SMS", "Email", "App"]
    const radioBtn3 = ["Send it Now", "Select A Specific Day"]
    return (
        <>
            <div className='campaigns-heade'>
                <h1 className='page-header'>Visit My Shop</h1>
            </div>
            <div className='d-xl-flex'>
                <div className='product-design-bg'>
                    <h2>Campaign Details</h2>
                    <h3>Enter campaign details</h3>
                    <div className='product-design-form'>
                        <div className='d-sm-flex justify-content-between'>
                            <div className='activeinput w-45'>
                                <label htmlFor="" className='label-text'>Campaign Name *</label>
                                <input type="text" {...register('name')} defaultValue={campaignData?.name} placeholder='visit X shop and get free coffee' />
                                {errors?.name && <p className='error_message'>{errors?.name?.message}</p>}
                            </div>
                            <div className='activeinput w-45'>
                                <div className='set-date-picker first-time-reward w-45' style={{ position: "relative" }}>
                                    <label htmlFor="" className='label-text'>Campaign Period *</label>
                                    <div className="date-picker mt-7" onClick={() => setOpen(!open)}>
                                        {data?.start_date ? data?.start_date : "Start Date"} {data?.end_date ? " - " + data?.end_date : " - End Date"}
                                    </div>
                                    <DateRangePicker
                                        {...register('start_date')}
                                        {...register('end_date')}
                                        open={open}
                                        toggle={toggle}
                                        onChange={(range) => {
                                            clearErrors('start_date')
                                            setValue('start_date', moment(range?.startDate).format("YYYY/MM/DD"))
                                            setValue('end_date', moment(range?.endDate).format("YYYY/MM/DD"))
                                            setDateRange(range)
                                        }}
                                    />
                                    <img src={datepicker} alt="" onClick={() => setOpen(!open)} />
                                    {errors?.start_date && <p className='error_message'>{errors?.start_date?.message}</p>}
                                </div>
                            </div>
                        </div>
                        <div className='img-template'>
                            <h4>Prizes</h4>
                            <Button className=''>Add Prizes</Button>
                        </div>
                        <div className='img-template'>
                            <h4 className='label-text'>Active </h4>
                            <Switch className='check-btn' onChange={(e) => setIsActive(!isActive)} checked={isActive} />
                        </div>
                        <div>
                            <h4 className='radio-lable'>Frequency? <span>(one time)</span></h4>
                            <div className='create-coupons-Select-Type'>

                                {radioBtn.map((item) => (
                                    <label className={` ${activeRadioBtn === item && "bg-red"} form-check`} htmlFor={`rediobtn${item}`} id='test1' onClick={() => handleRadioBtn(item)}>
                                        <input className="form-check-input" value={item}  {...register('frequency')} onChange={() => { clearErrors('frequency'); setValue('frequency', item) }} type="radio" name="flexRadioDefault" id={`rediobtn${item}`} />
                                        <label className="form-check-label" for={`rediobtn${item}`}>
                                            {item}
                                        </label>
                                    </label>
                                ))}

                            </div>
                            {errors?.frequency && <p className='error_message'>{errors?.frequency?.message}</p>}
                        </div>
                        <div>
                            <h4 className='radio-lable'>Customers to send?</h4>
                            <div className='create-coupons-Select-Type'>

                                {radioBtn2.map((item) => (
                                    <label className={` ${activeRadioBtn2 === item && "bg-red"} form-check`} htmlFor={`rediobtn2${item}`} id={item} onClick={() => handleRadioBtn2(item)}>
                                        <input className="form-check-input" value={item}  {...register('customer_to_send')} onChange={() => { clearErrors('customer_to_send'); setValue('customer_to_send', item) }} type="radio" name="flexRadioDefault2" id={`rediobtn2${item}`} />
                                        <label className="form-check-label" for={`rediobtn2${item}`}>
                                            {item}
                                        </label>
                                    </label>
                                ))}

                            </div>
                            {errors?.customer_to_send && <p className='error_message'>{errors?.customer_to_send?.message}</p>}
                        </div>
                        <div>
                            <h4 className='radio-lable'>In what ways this campaign should be sent to customers?</h4>
                            <div className='create-coupons-Select-Type'>

                                {CheckBoxBtn.map((item) => (
                                    <label className={`${checkbox.includes(item) && "bg-red"} form-check`} htmlFor={`checkbox${item}`} id={`checkbox${item}`} onClick={() => handlecheckbox(item)}>
                                        <input className="form-check-input" {...register('camp_sent_to_customer')} onChange={setValue('camp_sent_to_customer', checkbox)} type="checkbox" value={item} name="camp_sent_to_customer" id={`checkbox${item}`} checked={checkbox.includes(item)} />
                                        <label className="form-check-label" for={`checkbox${item}`} >
                                            {item}
                                        </label>
                                    </label>
                                ))}

                            </div>
                            {errors?.camp_sent_to_customer && <p className='error_message'>{errors?.camp_sent_to_customer?.message}</p>}
                        </div>
                        <div>
                            <h4 className='radio-lable'>When to launch this?</h4>
                            <div className='create-coupons-Select-Type'>

                                {radioBtn3.map((item) => (
                                    <label className={` ${activeRadioBtn3 === item && "bg-red"} form-check`} htmlFor={`rediobtn3${item}`} id={item} onClick={() => handleRadioBtn3(item)}>
                                        <input className="form-check-input" value={item}  {...register('when_to_launch')} onChange={() => { clearErrors('when_to_launch'); setValue('when_to_launch', item) }} type="radio" name="flexRadioDefault3" id={`rediobtn3${item}`} />
                                        <label className="form-check-label" for={`rediobtn3${item}`}>
                                            {item}
                                        </label>
                                    </label>
                                ))}

                            </div>
                            {errors?.when_to_launch && <p className='error_message'>{errors?.when_to_launch?.message}</p>}
                        </div>
                    </div>
                    <div className='start-promotional-campaign-footer'>
                        <Button onClick={() => navigate(id ? '/campaigns' : '/campaigns/start-campaign/automatic-campaign')} className='cancel-btn'>Back</Button>
                        <Button onClick={handleSubmit((data) => { handleCampaignData(data) })} className='submit-btn'>Launch</Button>
                    </div>
                </div>
                <div className='map-content'>
                    <iframe src="https://maps.google.com/maps?q=silver%20business%20point&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0"></iframe>
                </div>
            </div>
            {
                modalShow && <SuccessModal show={modalShow} onHide={() => goToListPage()} />
            }
        </>
    );
};

export default VisitMyShop;
