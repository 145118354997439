import React, { useEffect, useState } from "react";
import RadioButton from "../../../common/RadioButton";
import Close_button from "../../../assets/img/close_button.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const TimeSelect = ({
  gameLoop,
  setGameLoop,
  urlFields,
  setUrlFields,
  gameSelect,
}) => {
  const productRewardSchema = yup.object({
    Specific_product_URL: yup
      .array()
      .of(
        yup
          .string()
          .required("Product URL is required.")
          .matches(
            /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
            "Enter valid product URL"
          )
      )
      .required("Product URL is required."),
  });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    reset: reset1,
    formState: { errors },
    clearErrors: clearErrors1,
  } = useForm({
    resolver: yupResolver(productRewardSchema),
  });

  const handleAddField = () => {
    const newField = {
      id: urlFields.length + 1,
      value: "",
    };
    setUrlFields([...urlFields, newField]);
  };

  const handleDeleteField = (id) => {
    const updatedFields = urlFields.filter((field) => field.id !== id);

    // Update the IDs of the remaining fields after deletion
    const updatedFieldsWithIDs = updatedFields.map((field, index) => ({
      ...field,
      id: index + 1,
    }));

    setUrlFields(updatedFieldsWithIDs);
  };

  useEffect(() => {
    handleAddField();
  }, []);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
        }}
        className="w-100 time-select"
      >
        <div className="radio-Button">
          <RadioButton
            width={"100%"}
            checked={gameLoop === "url"}
            text={"Product Page Display"}
            id={"option1"}
            value={"url"}
            onChange={() => setGameLoop("url")}
            justifyContent="center"
          />
        </div>

        <div className="radio-Button">
          <RadioButton
            width={"100%"}
            checked={gameLoop === "time"}
            text={"Time-Based Display"}
            id={"option2"} // Change id value
            value={"time"}
            onChange={() => setGameLoop("time")}
            justifyContent="center"
          />
        </div>
      </div>
      {gameSelect.value !== "puzzle" && gameLoop === "url" ? (
        <p className="url-subtext">
          The game appears on specific product URLs; rewards promotion will
          apply to only those selected products.
        </p>
      ) : gameSelect.value === "puzzle" && gameLoop === "url" ? (
        <p className="url-subtext">
          The game appears on four specific product URLs, and rewards will only
          be available after collecting all four puzzles.
        </p>
      ) : (
        <p className="url-subtext">
          The game appears anywhere on the page 5 seconds since entered the
          e-shop; rewards promotion will apply to all e-shop products.
        </p>
      )}
      <div
        className="w-100"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {gameLoop === "url" ? (
          <div className="w-100">
            {gameSelect.value !== "puzzle" ? (
              <>
                {urlFields.map((field, index) => (
                  <div key={field.id} className="mt-2 mb-1">
                    {index > 0 && index === urlFields.length - 1 && (
                      <label className="url-subtext mb-2">
                        You can always enter more URL's
                      </label>
                    )}
                    <div
                      className="boomio_input_main"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <input
                        className="boomio_input"
                        type="text"
                        name={`Specific_product_URL[${index}].value`}
                        placeholder="https://"
                        onChange={(e) => {
                          const updatedFields = [...urlFields];
                          updatedFields[index].value = e.target.value;
                          setUrlFields(updatedFields);
                          if (
                            index === urlFields.length - 1 &&
                            e.target.value !== ""
                          ) {
                            handleAddField();
                          }
                        }}
                        value={field.value || ""}
                        aria-label="default input example"
                      />

                      {index > 0 && index !== urlFields.length - 1 && (
                        <button
                          type="button"
                          onClick={() => handleDeleteField(field.id)}
                          style={{
                            borderRadius: "0px 32px 32px 0px",
                            border: "0px solid transparent",
                            margin: "1px",
                            backgroundColor: "white",
                          }}
                        >
                          <div>
                            <img src={Close_button} alt="" />
                          </div>
                        </button>
                      )}
                    </div>
                    {index !== urlFields.length - 1 &&
                      (errors?.Specific_product_URL?.[index] ||
                        errors?.Specific_product_URL?.[index]?.type) && (
                        <span className="campaings_error">
                          {errors?.Specific_product_URL?.[index]?.message}
                        </span>
                      )}
                  </div>
                ))}
              </>
            ) : (
              <>
                {urlFields.map((field, index) => (
                  <div key={field.id} className="mt-2 mb-1">
                    <div
                      className="boomio_input_main"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <input
                        className="boomio_input"
                        type="text"
                        name={`Specific_product_URL[${index}].value`}
                        placeholder="https://"
                        onChange={(e) => {
                          const updatedFields = [...urlFields];
                          updatedFields[index].value = e.target.value;
                          setUrlFields(updatedFields);
                        }}
                        value={field.value || ""}
                        aria-label="default input example"
                      />
                    </div>
                    {index !== urlFields.length - 1 &&
                      (errors?.Specific_product_URL?.[index] ||
                        errors?.Specific_product_URL?.[index]?.type) && (
                        <span className="campaings_error">
                          {errors?.Specific_product_URL?.[index]?.message}
                        </span>
                      )}
                  </div>
                ))}
              </>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};

export default TimeSelect;
