import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ConfirmDialog from "../componets/modals/ConfirmDialog";
import AddCustomer from "../componets/modals/AddCustomer";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCampaignsById,
  getCampaigns,
  editCampaigns,
} from "redux/features/campaigns/campaignSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { t } from "i18next";
import actions from "../../assets/img/actions-dropdown.png";
import deleteIcon from "../../assets/img/delete-icon.png";
// import duplicateIcon from "../../assets/img/duplicate-icon.png";
import editIcon from "../../assets/img/edit-icon.png";
import filterIcon from "../../assets/img/filter-icon.png";
import searchIcon from "../../assets/img/search-icon.png";
import plusIcon from "../../assets/img/round-plus-icon.png";
import downArrow from "../../assets/img/down-arrow-black.png";
import headerIconNew from "../../assets/img/header-icon-new.png";
import exclamationCircle from "../../assets/img/ExclamationCircle.png";
import moment from "moment";
import { CampaignName } from "./campaignNameComponent";
import axios from "axios";
import { CampaignBaseUrl } from "helper/API/BaseUrl";
import Header from "layout/header/Header";
import AuthStorage from "helper/AuthStorage";
import StatusBar from "./CampaignsStatusBar";
import { getDate } from "date-fns/esm";
import { patchBusinessData } from "redux/features/business/businessSlice";

const Campaigns = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [campaignsData, setCampaignsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentCount, setCurrentCount] = useState(1);
  const [modelType, setModelType] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [dropdownmanu, setdropdownmanu] = useState();
  const [statusChange, setStatusChange] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [onhoverCampaign, setonhoverCampaign] = useState(false);
  const [deleteId, setdeleteId] = useState([]);
  const [apiOrder, setApiOrder] = useState(1);
  const [sortBy, setSortBy] = useState("created_at");
  const [sortDirection, setSortDirection] = useState(-1);
  const [isChecked, setIsChecked] = useState(false);
  const [msg, setMsg] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [statusChangeProps, setStatusChangeProps] = useState({});
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [typeFilterAnchorEl, setTypeFilterAnchorEl] = useState(null);
  const [statusFilterAnchorEl, setStatusFilterAnchorEl] = useState(null);
  const [fieldsAnchorEl, setFieldsAnchorEl] = useState(null);
  const openFilter = Boolean(filterAnchorEl);
  const openTypeFilter = Boolean(typeFilterAnchorEl);
  const openStatusFilter = Boolean(statusFilterAnchorEl);
  const openFieldsSelector = Boolean(fieldsAnchorEl);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [visibleFields, setVisibleFields] = useState({
    status: true,
    reward: true,
    engaged: true,
    receivedReward: true,
    closedPurchase: true,
    revenue: true,
    endDate: true,
  })
  const [fieldLabels, setFieldLabels] = useState({
    status: 'Status',
    reward: 'Reward',
    engaged: 'Engaged',
    receivedReward: 'Received reward',
    closedPurchase: 'Closed purchase',
    revenue: 'Revenue',
    endDate: 'End date',
  })
  const [fieldCheckBoxes, setFieldCheckBoxes] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true
  ])

  const businessState = useSelector((state) => state.businessState.data);

  const [checkboxes, setCheckboxes] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [typeFilters, setTypefilters] = useState([
    false,
    false,
    false,
    false,
  ]);

  const [statusFilters, setStatusfilters] = useState([
    false,
    false,
    false,
    false,
  ]);

  const [workingFilters, setWorkingFilters] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,]
  );

  const campaignTypes = ["Boost a product", "Lead to a purchase", "Increase browsing time", "Boost specific products discovery"]
  const campaignStatuses = ["active", "draft", 'finished', 'stopped']

  const commonHeaders = {
    "Content-Type": "application/json",
    Authorization: AuthStorage.getToken(),
  };

  useEffect(() => {
    const fields = {
      status: businessState?.params?.extra_fields?.status === undefined ? true : businessState?.params?.extra_fields?.status,
      reward: businessState?.params?.extra_fields?.reward === undefined ? true : businessState?.params?.extra_fields?.reward,
      engaged: businessState?.params?.extra_fields?.engaged === undefined ? true : businessState?.params?.extra_fields?.engaged,
      receivedReward: businessState?.params?.extra_fields?.receivedReward === undefined ? true : businessState?.params?.extra_fields?.receivedReward,
      closedPurchase: businessState?.params?.extra_fields?.closedPurchase === undefined ? true : businessState?.params?.extra_fields?.closedPurchase,
      revenue: businessState?.params?.extra_fields?.revenue === undefined ? true : businessState?.params?.extra_fields?.revenue,
      endDate: businessState?.params?.extra_fields?.endDate === undefined ? true : businessState?.params?.extra_fields?.endDate,
    }
    let boxes = [true, true, true, true, true, true, true]
    Object.keys(fields).map((key, index) => { boxes[index] = fields[key] })
    setVisibleFields(fields)
    setFieldCheckBoxes(boxes)
  }, [businessState]);

  useEffect(() => {
    const totalPageRounded = Math.floor(Math.round(totalCount / perPage));
    const totalPage = totalCount / perPage;
    let pages;
    if (totalPage > totalPageRounded) {
      pages = totalPageRounded + 1;
      setTotalPages(pages);
    } else {
      pages = totalPageRounded;
      setTotalPages(pages);
    }
  }, [totalCount, perPage]);

  useEffect(() => {
    if (currentCount >= totalPages) {
      setCurrentCount(currentCount);
    } else {
      setCurrentCount(pageNumber);
    }
  }, [pageNumber]);

  useEffect(() => {
    getCampaignsData(1, perPage, apiOrder);
    return () => {
      setCampaignsData([]);
      setTotalCount(0);
    };
  }, [perPage, apiOrder]);


  const toggleField = (key) => {
    setVisibleFields(current => ({
      ...current,
      [key]: !visibleFields[key]
    }))
  }

  const handleSearchInputChange = (event) => {
    setSearchInputValue(event.target.value);
  };

  const saveFieldSettings = async () => {
     try {
      let body = {
        params: { extra_fields: { ...businessState?.params.extra_fields, ...visibleFields } }
      };
     await updateHandler(body);
    } catch (e) {
      console.error(e)
    }
  };

  const updateHandler = async (postdata) => {
    dispatch(patchBusinessData(postdata))
      .then(unwrapResult)
      .then((res) => {
      });
  }

  const changeTypeFilters = (ind) => {
    let typeFiltersCopy = [...typeFilters];
    typeFiltersCopy[ind] = !typeFilters[ind];
    setTypefilters([...typeFiltersCopy]);
  }

  const changeFieldCheckBoxes = (ind) => {
    let fieldCheckBoxesCopy = [...fieldCheckBoxes];
    fieldCheckBoxesCopy[ind] = !fieldCheckBoxes[ind];
    setFieldCheckBoxes([...fieldCheckBoxesCopy]);
  }

  const changeStatusFilters = (ind) => {
    let statusFiltersCopy = [...statusFilters];
    statusFiltersCopy[ind] = !statusFilters[ind];
    setStatusfilters([...statusFiltersCopy]);
  }

  const resetFilters = () => {
    let typeFiltersCopy = [...typeFilters];
    let statusFiltersCopy = [...statusFilters];
    let workingFiltersCopy = [...workingFilters];
    typeFiltersCopy.forEach((e, i) => { typeFiltersCopy[i] = false })
    statusFiltersCopy.forEach((e, i) => { statusFiltersCopy[i] = false })
    workingFiltersCopy.forEach((e, i) => { workingFiltersCopy[i] = true })
    setTypefilters([...typeFiltersCopy]);
    setStatusfilters([...statusFiltersCopy]);
    setWorkingFilters([...workingFiltersCopy]);
    setFilterAnchorEl(null)
  }

  const applyFilters = () => {
    let workingFiltersCopy = [...workingFilters];
    workingFiltersCopy.forEach((e, i) => {
      if (i < 4) { workingFiltersCopy[i] = statusFilters[i] }
      if (i < 4 && !statusFilters[0] && !statusFilters[1] && !statusFilters[2] && !statusFilters[3]) { workingFiltersCopy[i] = true }
      if (i >= 4) {
        workingFiltersCopy[i] = typeFilters[i - 4]
      }
      if (i >= 4 && !typeFilters[0] && !typeFilters[1] && !typeFilters[2] && !typeFilters[3]) { workingFiltersCopy[i] = true }
    })
    setWorkingFilters([...workingFiltersCopy]);
    setFilterAnchorEl(null)
  }

  const handleFilterClick = (e) => {
    setFilterAnchorEl(e.target);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };
  const handleTypeFilterClick = (e) => {
    setTypeFilterAnchorEl(e.target);
  };

  const handleTypeFilterClose = () => {
    setTypeFilterAnchorEl(null);
  };

  const handleStatusFilterClick = (e) => {
    setStatusFilterAnchorEl(e.target);
  };

  const handleStatusFilterClose = () => {
    setStatusFilterAnchorEl(null);
  };

  const handleFieldsClick = (e) => {
    setFieldsAnchorEl(e.target);
  }

  const handleFieldsClose = () => {
    setFieldsAnchorEl(null);
  };

  const handleDelete = () => {
    deleteId.forEach((e) => {
      dispatch(deleteCampaignsById(e))
        .then(unwrapResult)
        .then((result) => {
          getCampaignsData(1, perPage, -1);
        })
        .catch((error) => {
          console.error(error);
        });
    })
    setdeleteId([])
    setDeleteModal(false)
  }

  const deleteAll = () => {
    const campaignsOnScreen = campaignsData
      .sort((a, b) =>
        a[sortBy] > b[sortBy]
          ? sortDirection
          : -1 * sortDirection
      )
      .filter((cmp) =>
        (cmp.status === 'active' && workingFilters[0]) ||
        (cmp.status === 'draft' && workingFilters[1]) ||
        (cmp.status === 'finished' && workingFilters[2]) ||
        (cmp.status === 'inactive' && workingFilters[3])
      )
      .filter((cmp =>
        (cmp.campaign_type === "boost_product" && workingFilters[4]) ||
        (cmp.campaign_type === 'lead_to_purchase' && workingFilters[5]) ||
        (cmp.campaign_type === 'increase_browsing_time' && workingFilters[6]) ||
        (cmp.campaign_type === 'boost_specific_product' && workingFilters[7])))
      .filter((cmp) => searchInputValue === '' || cmp.campaign_name.toLowerCase().includes(searchInputValue.toLowerCase()))

    const toBeDeleted = campaignsOnScreen.filter((cmp, i) => checkboxes[i] === true).map((cmp) => cmp.id)
    setdeleteId(toBeDeleted)
    setCheckboxes([false, false, false, false, false, false, false, false, false, false,])
  }


  const showAllFields = async () => {
    const allBoxes = [true, true, true, true, true, true, true]
    const allFields = {
      status: true,
      reward: true,
      engaged: true,
      receivedReward: true,
      closedPurchase: true,
      revenue: true,
      endDate: true,
    }
    setFieldCheckBoxes(allBoxes);
    setVisibleFields(allFields)
    let body = {
    params: { extra_fields: { ...businessState?.params.extra_fields, ...allFields } }
    };
     try {
      await updateHandler(body);
    } catch (e) {
      console.error(e)
    }
  }

  const getCampaignsData = async (pageNumber, limit, orderBy) => {
    await dispatch(getCampaigns([pageNumber, limit, orderBy]))
      .then(unwrapResult)
      .then((result) => {
        if (result && result.response) {
          if (result.response.results) {
            setCampaignsData(result.response.results);
          }
          if (result.response.count) {
            setTotalCount(result.response.count);
          }
        }
        return result?.data?.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const nextPage = () => {
    const nextpage = pageNumber + 1;
    if (nextpage <= totalPages) {
      getCampaignsData(nextpage, perPage, apiOrder);
      setPageNumber(nextpage);
    } else {
      getCampaignsData(pageNumber, perPage, apiOrder);
    }
  };

  const previousPage = () => {
    const nextpage = pageNumber - 1;
    if (nextpage > 0) {
      getCampaignsData(nextpage, perPage, apiOrder);
      setPageNumber(nextpage);
      setCurrentCount(nextpage);
    } else {
      getCampaignsData(pageNumber, perPage, apiOrder);
    }
  };

  const openMenu = (i) => {
    if (i === dropdownmanu) {
      setdropdownmanu();
    } else {
      setdropdownmanu(i);
    }
  };
  const openStatusChange = (i) => {
    if (i === statusChange) {
      setStatusChange();
    } else {
      setStatusChange(i);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [statusAnchorEl, setStatusAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const openStatus = Boolean(statusAnchorEl);

  const handleClickK = (event, i) => {
    setAnchorEl(event.currentTarget);
    openMenu(i);
  };
  const handleClickStatus = (event, i) => {
    setStatusAnchorEl(event.currentTarget);
    openStatusChange(i);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusClose = () => {
    setStatusAnchorEl(null);
  };

  const getRewardsRender = (item) => {
    const rewardType = item?.params?.selectRewardType1;

    let title = "";
    let desc = "";

    switch (rewardType) {
      case "FREE_SHIPPING":
        title = "Free Shipping";
        desc = `${item?.params?.rewardExpirationDate
          ? `(${item?.params?.rewardExpirationDate} day expiration)`
          : ""
          }`;
        break;
      case "DISCOUNT_PERCENTAGE":
        title = item?.params?.selectRewardTypeDiscountPercentage + "% Discount";
        desc = `${item?.params?.rewardExpirationDate
          ? `(${item?.params?.rewardExpirationDate} day expiration)`
          : ""
          }`;
        break;
      case "DISCOUNT_CURRENCY":
        title = item?.params?.selectRewardTypeDiscountDollar + "$ Discount";
        desc = `${item?.params?.rewardExpirationDate
          ? `(${item?.params?.rewardExpirationDate} day expiration)`
          : ""
          }`;
        break;
      default: //do nothing
    }

    return (
      <div>
        {title} <br />
        {desc}
      </div>
    );
  };

  const capFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleChange = (ind) => {
    let checkboxesCopy = [...checkboxes];
    checkboxesCopy[ind] = !checkboxes[ind];
    setCheckboxes([...checkboxesCopy]);
  };

  const handleChangeAll = (val) => {
    let checkboxesCopy = [...checkboxes];
    checkboxesCopy.forEach((e, i) => (checkboxesCopy[i] = !val));
    setCheckboxes([...checkboxesCopy]);
  };

  const handleMenuItemClick = (id) => {
    navigate(`/campaigns/boost-product?id=${id}`);
  };

  // const duplicateCampaign = (id) => {
  //   // console.log('campaignsData.id', campaignsData.filter((c)=> c.id === id))
  //   // console.log('id', id)
  //   let returnData = campaignsData.filter((c) => c.id === id)[0]
  //   returnData = {
  //     ...returnData,
  //     id: undefined,
  //     b_id: undefined,
  //     updated_at: undefined,
  //     created_at: undefined,
  //     campaign_subtype: '',
  //     status: "draft"
  //   }
  //   delete returnData.campaign_subtype
  //   delete returnData.logo
  //   delete returnData.deletedAt
  //   delete returnData.camp_engaged
  //   delete returnData.camp_received_reward
  //   delete returnData.camp_closed_purchase
  //   delete returnData.camp_closed_purchase_perc
  //   delete returnData.camp_revenue
  //   delete returnData.camp_revenue_currency
  //   const url = returnData.params.productUrl.map((url) => url = `${url}?/${Math.random()}`)
  //   const urls = returnData.params.productUrls.map((url) => url = `${url}?/${Math.random()}`)
  //   returnData.params.productUrl = url
  //   returnData.params.productUrls = urls

  //   console.log('url ===', url);
  //   console.log('urls ===', urls);
  //   console.log('data', returnData)

  //   startCampaigns()

  //   async function startCampaigns() {
  //     try {
  //       const action = createCampaigns(returnData);
  //       const response = await dispatch(action);
  //       if (response.payload?.isError) {
  //         throw response.payload;
  //       }
  //       ReactGA.event({
  //         action: "Launched campaign",
  //         category: "General, on selfservice",
  //         value: "",
  //       });

  //       navigate("/campaigns");
  //     } catch (error) {
  //       console.log("Error:", error);
  //     }
  //   }

  //   window.location.reload()
  // }


  const isAllowed = (c) => {
    const endDate = moment(c.campaign_end).toDate();
    let nextDate = new Date();
    nextDate.setTime(endDate.getTime() + 86400000);
    if (
      (c.status === "active" || c.status === "inactive") &&
      nextDate > new Date()
    )
      return true;
    return false;
  };

  const handleStatusChange = (id, status, type, url) => {
    status === "active"
      ? setMsg("Are you sure you want to activate this campaign?")
      : setMsg("Are you sure you want to stop this campaign?");
    status === "active" ? setButtonText("Activate") : setButtonText("Stop");
    openDialogBox();
    setStatusChangeProps({ id: id, status: status, type: type, url: url });
  };
  const [openDialog, handleDisplay] = React.useState(false);

  const handleDialogClose = () => {
    handleDisplay(false);
  };

  const handleDialogExecute = () => {
    handleDisplay(false);
    changeStatus(
      statusChangeProps.id,
      statusChangeProps.status,
      statusChangeProps.type,
      statusChangeProps.url
    );
  };

  const openDialogBox = () => {
    handleDisplay(true);
  };

  const changeStatus = async (id, status, type, url) => {
    const newStatus = {
      campaign_type: type,
      params: {
        productUrl: url,
      },
      status: status,
    };
    try {
      const response = await dispatch(editCampaigns({ id, ...newStatus }));
      if (response.payload?.isError) {
        throw response.payload;
      }
      window.location.reload();
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <>
      <Header title={t("campaign.header")} />

      <div className="customer-table">
        <div className="table-bg">
          <h1 className="page-header mb-0 gradient-color-font">
            {"YOUR CAMPAIGNS"}
          </h1>
          <div
            className="d-flex mb-4 position-relative"
            style={{ flexWrap: "wrap", gap: "10px", justifyContent: 'space-between' }}
          >
            <div className="d-flex" style={{ flexWrap: "wrap", gap: "10px" }}>

              <div className="campaign-actions w300" style={{ justifyContent: 'flex-start' }}>
                <img src={searchIcon} style={{ margin: '10px' }} alt="" />
                <input className='capmaign-search' type="text" placeholder="Search in Campaigns" value={searchInputValue}
                  onChange={handleSearchInputChange} />
              </div>
              <div className="campaign-actions w52" style={{ width: "10px" }}>
                <img src={filterIcon} alt="" style={{ padding: '16px' }} onClick={handleFilterClick} />
                <Menu className="filter-menu"
                  anchorEl={filterAnchorEl}
                  id="filter-menu"
                  open={openFilter}
                  onClose={handleFilterClose}
                  // onClick={handleFilterClose}
                  autoFocus={false}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      paddingTop: "0px",
                      overflow: "visible",
                      filter:
                        "drop-shadow(0px 0px 1px #E1E1E1)",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },

                      // "&:before": {
                      //   content: '""',
                      //   display: "block",
                      //   position: "absolute",
                      //   top: 0,
                      //   right: 14,
                      //   width: 10,
                      //   height: 10,
                      //   bgcolor: "background.paper",
                      //   transform:
                      //     "translateY(-50%) rotate(45deg)",
                      //   zIndex: 0,
                      // },
                    },
                  }}
                  // transformOrigin={{
                  //   horizontal: "right",
                  //   vertical: "top",
                  // }}
                  // anchorOrigin={{
                  //   horizontal: "right",
                  //   vertical: "bottom",
                  // }}
                >
                  <div className="filter-menu-header" > Filter By</div>
                  <Divider />
{/* removed temporary until url based campaigns will be returned */}
                  {/* <MenuItem className="filter-menu-item"
                    onClick={handleTypeFilterClick}
                  >
                    <span> Campaign type</span> <img src={downArrow} alt="" />
                  </MenuItem  > */}
                  <Menu className="filter-menu"
                    anchorEl={typeFilterAnchorEl}
                    id="type-filter-menu"
                    open={openTypeFilter}
                    onClose={handleTypeFilterClose}
                    // onClick={handleTypeFilterClose}
                    autoFocus={false}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        paddingTop: "0px",
                        overflow: "visible",
                        filter:
                          "drop-shadow(0px 0px 1px #E1E1E1)",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        // "&:before": {
                        //   content: '""',
                        //   display: "block",
                        //   position: "absolute",
                        //   top: 0,
                        //   right: 14,
                        //   width: 10,
                        //   height: 10,
                        //   bgcolor: "background.paper",
                        //   transform:
                        //     "translateY(-50%) rotate(45deg)",
                        //   zIndex: 0,
                        // },
                      },
                    }}
                    transformOrigin={{
                      horizontal: "right",
                      vertical: "top",
                    }}
                    anchorOrigin={{
                      horizontal: "right",
                      vertical: "bottom",
                    }}
                  >
                    {campaignTypes.map((type, i) => {
                      return (<MenuItem style={{width: '300px'}} key={i} onClick={() => changeTypeFilters(i)} > <input
                        type="checkbox"
                        value="above"
                        name="checkbox"
                        className="checkbox-input-body"
                        // onChange={() => changeTypeFilters(i)}
                        checked={typeFilters[i]}
                        id={i}
                      /><div style={{maxWidth: '200px',     whiteSpace: 'pre-line'}}>{type}</div> </MenuItem>)
                    })}
                  </Menu>

                  <MenuItem className="filter-menu-item"
                    onClick={handleStatusFilterClick}
                  ><span>Status</span> <img src={downArrow} alt="" />
                  </MenuItem>
                  <Menu className="filter-menu"
                    anchorEl={statusFilterAnchorEl}
                    id="status-filter-menu"
                    open={openStatusFilter}
                    onClose={handleStatusFilterClose}
                    // onClick={handleTypeFilterClose}
                    autoFocus={false}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        paddingTop: "0px",
                        overflow: "visible",
                        filter:
                          "drop-shadow(0px 0px 1px #E1E1E1)",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        // "&:before": {
                        //   content: '""',
                        //   display: "block",
                        //   position: "absolute",
                        //   top: 0,
                        //   right: 14,
                        //   width: 10,
                        //   height: 10,
                        //   bgcolor: "background.paper",
                        //   transform:
                        //     "translateY(-50%) rotate(45deg)",
                        //   zIndex: 0,
                        // },
                      },
                    }}
                    transformOrigin={{
                      horizontal: "right",
                      vertical: "top",
                    }}
                    anchorOrigin={{
                      horizontal: "right",
                      vertical: "bottom",
                    }}
                  >
                    {campaignStatuses.map((status, i) => {
                      return (<MenuItem key={i} onClick={() => changeStatusFilters(i)}> <input
                        type="checkbox"
                        value="above"
                        name="checkbox"
                        className="checkbox-input-body"

                        checked={statusFilters[i]}
                        id={i}
                      /><StatusBar status={status} end={(status === 'finished') ? new Date(getDate(new Date()) - 1) : new Date()} /></MenuItem>)
                    })}
                  </Menu>


                  {/* <MenuItem className="filter-menu-item"
                    onClick={(e) => {
                      console.log('reward')
                    }}
                  > <span> Reward </span> <img src={downArrow} alt="" />
                  </MenuItem> */}
                   {/* <div className="filter-menu-header" > Filter By</div> */}
                   <Divider/>

                  <div className="filter-menu-footer">
                    <button  style={{ width: "170px", height: '50px'}}className="outline_btn_dark btn btn-primary" onClick={applyFilters}>
                      <span> Apply filter</span>
                    </button>
                    <span className="gradient-color-font gradient-underline" style={{ cursor: 'pointer' }} onClick={resetFilters}>Reset</span>
                  </div>
                </Menu>
              </div>
            </div>

            <div className="campaign-actions w120">
              {/* <img src={editIcon} alt="" />  */}
              <img src={plusIcon} alt="" onClick={handleFieldsClick} />
              {/* <img src={duplicateIcon} alt="" /> */}
              <img src={deleteIcon} alt="" onClick={() => {
                setDeleteModal(true);
                setdropdownmanu();
                deleteAll()
              }} /></div>
            <Menu className="filter-menu"
              anchorEl={fieldsAnchorEl}
              id="fields-menu"
              open={openFieldsSelector}
              onClose={handleFieldsClose}
              // onClick={handleTypeFilterClose}
              autoFocus={false}
              PaperProps={{
                elevation: 0,
                sx: {
                  paddingTop: "0px",
                  overflow: "visible",
                  height: '425px',
                  filter:
                    "drop-shadow(0px 0px 1px #E1E1E1)",
                  // height: '700px', 
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  // "&:before": {
                  //   content: '""',
                  //   display: "block",
                  //   position: "absolute",
                  //   top: 0,
                  //   right: 14,
                  //   width: 10,
                  //   height: 10,
                  //   bgcolor: "background.paper",
                  //   transform:
                  //     "translateY(-50%) rotate(45deg)",
                  //   zIndex: 0,
                  // },
                },
              }}
              transformOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
              >
               <div className="filter-menu-header" > View by</div>
              <Divider></Divider>
               

              {Object.keys(fieldLabels).map((key, index) => {
                return (
                  <MenuItem key={index} onClick={() => {
                    // console.log('variable', key)
                  }}>
                    <input
                      type="checkbox"
                      value="above"
                      name="checkbox"
                      className="checkbox-input-body"
                      onChange={() => {
                        changeFieldCheckBoxes(index);
                        toggleField(key)
                      }}
                      checked={fieldCheckBoxes[index]}
                      id={index}
                    />
                    <span>{fieldLabels[key]}</span>
                  </MenuItem>

                );
              })}
              <Divider></Divider>
            
                <div  className="filter-menu-footer">
                 
                  <button className="outline_btn_dark btn btn-primary" onClick={saveFieldSettings} style={{ width: "170px", height: '50px' }}> <span>Save</span></button><div className="gradient-color-font gradient-underline" style={{ cursor: 'pointer' }} onClick={showAllFields}> reset</div>

                </div>
           
            </Menu>
          </div>
          <div className="table-bg-inner">
            <div className="table-scroll">
              <div>
                <table>
                  <thead className="thead-bg">
                    <tr>
                      <th scope="col">
                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            value="above"
                            name="checkbox"
                            checked={isChecked}
                            onChange={() => {
                              handleChangeAll(isChecked);
                              setIsChecked((prev) => !prev);
                            }}
                            // className="checkbox-input"
                            className="checkbox-input-body"
                          />
                          {/* <div className="d-flex"></div> */}
                          <span
                            onClick={() => {
                              setSortBy("campaign_name");
                              setSortDirection(sortDirection === 1 ? -1 : 1);
                            }}
                            className="headr-text"
                          >
                            Campaign Name
                          </span>
                          <img
                            src={headerIconNew}
                            alt=""
                            className="icon-6"
                            onClick={() => {
                              setSortBy("campaign_name");
                              setSortDirection(sortDirection === 1 ? -1 : 1);
                            }}
                          />
                        </div>
                      </th>
                      {visibleFields.status && <th scope="col" style={{ whiteSpace: "nowrap" }}>
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            // setApiOrder(apiOrder === -1 ? 0 : -1);
                            setSortBy("status");
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">Status</span>
                          <img src={headerIconNew} className="icon-6" alt="" />
                        </div>
                      </th>}

                      {visibleFields.reward && <th scope="col">
                        <div
                          onClick={() => {
                            // setApiOrder(apiOrder === -1 ? 0 : -1);
                            // setSortBy('params.selectRewardType1')
                            // setSortDirection(sortDirection === 1 ? -1 : 1)
                          }}
                        >
                          <span className="headr-text">Reward</span>
                          <img src={headerIconNew} className="icon-6" alt="" />
                        </div>
                      </th>}
                      {visibleFields.engaged && <th scope="col">
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            // setApiOrder(apiOrder === -1 ? 0 : -1);
                            setSortBy("camp_engaged");
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">Engaged</span>
                          <img src={headerIconNew} className="icon-6" alt="" />
                        </div>
                      </th>}
                      {visibleFields.receivedReward && <th scope="col">
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            // setApiOrder(apiOrder === -1 ? 0 : -1);
                            setSortBy("camp_received_reward");
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">Received Reward</span>
                          <img src={headerIconNew} className="icon-6" alt="" />
                        </div>
                      </th>}
                      {visibleFields.closedPurchase && <th scope="col">
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            // setApiOrder(apiOrder === -1 ? 0 : -1);
                            setSortBy("camp_closed_purchase");
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">Closed Purchase</span>
                          <img src={headerIconNew} className="icon-6" alt="" />
                        </div>
                      </th>}
                      {visibleFields.revenue && <th scope="col">
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            // setApiOrder(apiOrder === -1 ? 0 : -1);
                            setSortBy("camp_revenue");
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">Revenue</span>
                          <img src={headerIconNew} className="icon-6" alt="" />
                        </div>
                      </th>}
                      {visibleFields.endDate && <th scope="col" style={{ whiteSpace: "nowrap" }}>
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            // setApiOrder(apiOrder === -1 ? 0 : -1);
                            setSortBy("campaign_end");
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">
                            {t("campaign.table.end_date")}
                          </span>
                          <img src={headerIconNew} className="icon-6" alt="" />
                        </div>
                      </th>}
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <ConfirmDialog open={openDialog} onClose={ handleDialogClose} img={exclamationCircle}   text={msg} cancelText={'Cancel'} onCancel={handleDialogClose} actionText={buttonText} onAction={handleDialogExecute}/>
                  <tbody>
                    {/* replicate all sort and filters parameters to function deleteAll */}
                    {campaignsData?.length > 0 ? (
                      campaignsData
                        .sort((a, b) =>
                          a[sortBy] > b[sortBy]
                            ? sortDirection
                            : -1 * sortDirection
                        )
                        .filter((cmp) =>
                          (cmp.status === 'active' && workingFilters[0]) ||
                          (cmp.status === 'draft' && workingFilters[1]) ||
                          (cmp.status === 'finished' && workingFilters[2]) ||
                          (cmp.status === 'inactive' && workingFilters[3])
                        )
                        .filter((cmp =>
                          (cmp.campaign_type === "boost_product" && workingFilters[4]) ||
                          (cmp.campaign_type === 'lead_to_purchase' && workingFilters[5]) ||
                          (cmp.campaign_type === 'increase_browsing_time' && workingFilters[6]) ||
                          (cmp.campaign_type === 'boost_specific_product' && workingFilters[7])))
                        .filter((cmp) => searchInputValue === '' || cmp.campaign_name.toLowerCase().includes(searchInputValue.toLowerCase()))
                        .map((item, i) => {
                          return (
                            <tr key={item.id}>
                              <td>
                                <div className="d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    value="above"
                                    name="checkbox"
                                    className="checkbox-input-body"
                                    onChange={() => handleChange(i)}
                                    checked={checkboxes[i]}
                                    id={i}
                                  />
                                  <div
                                    className="headr-text purple-black"
                                    onClick={() => {
                                      setonhoverCampaign(true);
                                    }}
                                  >
                                    {" "}
                                    <CampaignName
                                      name={item?.campaign_name}
                                      onChange={(changedVal) => {
                                        axios
                                          .patch(
                                            `${CampaignBaseUrl}campaign/name/${item.id}`,
                                            {
                                              name: changedVal,
                                            },
                                            {
                                              headers: commonHeaders,
                                            }
                                          )
                                          .then((res) => {
                                            getCampaignsData(
                                              1,
                                              perPage,
                                              apiOrder
                                            );
                                          });
                                      }}
                                    />
                                  </div>
                                </div>
                              </td>
                              {visibleFields.status && <td>
                                <IconButton
                                  onClick={(e) => {
                                    handleClickStatus(e, i);
                                  }}
                                  id={"statusMenu"}
                                  size="small"
                                  aria-controls={
                                    openStatus ? "statusMenu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={
                                    openStatus ? "true" : undefined
                                  }
                                >
                                  <StatusBar
                                    status={item?.status}
                                    end={item?.campaign_end}
                                  />
                                </IconButton>
                              </td>}
                              {statusChange === i && (
                                <Menu
                                  anchorEl={statusAnchorEl}
                                  id="statusMenu"
                                  open={openStatus}
                                  onClose={handleStatusClose}
                                  onClick={handleStatusClose}
                                  PaperProps={{
                                    elevation: 0,
                                    sx: {
                                      overflow: "visible",
                                      filter:
                                        "drop-shadow(0px 0px 1px #E1E1E1)",
                                      mt: 1.5,
                                      "& .MuiAvatar-root": {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                      },
                                      "&:before": {
                                        content: '""',
                                        display: "block",
                                        position: "absolute",
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: "background.paper",
                                        transform:
                                          "translateY(-50%) rotate(45deg)",
                                        zIndex: 0,
                                      },
                                    },
                                  }}
                                  transformOrigin={{
                                    horizontal: "right",
                                    vertical: "top",
                                  }}
                                  anchorOrigin={{
                                    horizontal: "right",
                                    vertical: "bottom",
                                  }}
                                >
                                  <MenuItem>
                                    {isAllowed(item) && (
                                      <div
                                        onClick={() => {
                                          handleStatusChange(
                                            item?.id,
                                            "active",
                                            item?.campaign_type,
                                            item?.params?.productUrl
                                          );
                                        }}
                                      >
                                        {/* {(isAllowed(item) || draftReady(item)) && <div  onClick={() => { handleStatusChange(item?.id, 'active', item?.campaign_type, item?.params?.productUrl) }} > */}
                                        <StatusBar
                                          status={"active"}
                                          end={new Date()}
                                        />
                                      </div>
                                    )}
                                    {!isAllowed(item) && (
                                      <div
                                        style={{ opacity: 0.3 }}
                                        class={"cursor-default"}
                                      >
                                        {/* {!(isAllowed(item) || draftReady(item)) && <div style={{ opacity: 0.3 }} class={'cursor-default'}  > */}
                                        <StatusBar
                                          status={"active"}
                                          end={new Date()}
                                        />
                                      </div>
                                    )}
                                  </MenuItem>
                                  <Divider />
                                  <MenuItem>
                                    {isAllowed(item) && (
                                      <div
                                        onClick={() => {
                                          handleStatusChange(
                                            item?.id,
                                            "inactive",
                                            item?.campaign_type,
                                            item?.params?.productUrl
                                          );
                                        }}
                                      >
                                        <StatusBar
                                          status={"inactive"}
                                          end={new Date()}
                                        />
                                      </div>
                                    )}
                                    {!isAllowed(item) && (
                                      <div
                                        style={{ opacity: 0.3 }}
                                        class={"cursor-default"}
                                      >
                                        <StatusBar
                                          status={"inactive"}
                                          end={new Date()}
                                        />
                                      </div>
                                    )}
                                  </MenuItem>
                                </Menu>
                              )}

                              {visibleFields.reward && <td style={{ whiteSpace: "nowrap" }}>
                                <span className="sub-text-table">
                                  {getRewardsRender(item)}
                                </span>
                              </td>}
                              {visibleFields.engaged && <td>
                                <span className="headr-text purple-black">
                                  {" "}
                                  {item?.camp_engaged}
                                </span>
                              </td>}
                              {visibleFields.receivedReward && <td>
                                <span className="headr-text purple-black">
                                  {" "}
                                  {item?.camp_received_reward}
                                </span>
                              </td>}
                              {visibleFields.closedPurchase && <td>
                                <span className="headr-text purple-black">
                                  {" "}
                                  {item?.camp_closed_purchase_perc ?? 0}
                                  {"%"}
                                </span>
                              </td>}
                              {visibleFields.revenue && <td>
                                <span className="headr-text purple-black">
                                  {item?.camp_revenue ?? 0}{" "}
                                  {item?.camp_revenue_currency}
                                </span>
                              </td>}
                              {visibleFields.endDate && <td>
                                <div className="d-flex">
                                  <div className="d-flex justify-content-between w-100">
                                    <span className="headr-text align-self-center">
                                      {moment(item?.campaign_end).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </td>}
                              <td>
                                <IconButton
                                  onClick={(e) => {
                                    handleClickK(e, i);
                                  }}
                                  size="small"
                                  sx={{ ml: 2 }}
                                  aria-controls={
                                    open ? "account-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                >
                                  <img
                                    src="./assets/img/three-dot.png"
                                    alt=""
                                    height="18px"
                                  />
                                </IconButton>
                                {dropdownmanu === i && (
                                  <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    PaperProps={{
                                      elevation: 0,
                                      sx: {
                                        overflow: "visible",
                                        filter:
                                          "drop-shadow(0px 0px 1px #E1E1E1)",
                                        mt: 1.5,
                                        "& .MuiAvatar-root": {
                                          width: 32,
                                          height: 32,
                                          ml: -0.5,
                                          mr: 1,
                                        },
                                        // "&:before": {
                                        //   content: '""',
                                        //   display: "block",
                                        //   position: "absolute",
                                        //   top: 0,
                                        //   right: 14,
                                        //   width: 10,
                                        //   height: 10,
                                        //   bgcolor: "background.paper",
                                        //   transform:
                                        //     "translateY(-50%) rotate(45deg)",
                                        //   zIndex: 0,
                                        // },
                                      },
                                    }}
                                    transformOrigin={{
                                      horizontal: "right",
                                      vertical: "top",
                                    }}
                                    anchorOrigin={{
                                      horizontal: "right",
                                      vertical: "bottom",
                                    }}
                                  >
                                    <MenuItem
                                      onClick={(e) => {
                                        handleMenuItemClick(item.id);
                                      }}
                                    >
                                      <img
                                        // src="./assets/img/Edit.png"
                                        src={editIcon}
                                        alt=""
                                        className="me-2"
                                      />
                                      {t("campaign.action.edit")}
                                    </MenuItem>
                                    <Divider />
                                    {/* <MenuItem
                                      onClick={(e) => {
                                        duplicateCampaign(item.id);
                                      }}
                                    >
                                      <img
                                        // src="./assets/img/Edit.png"
                                        src={duplicateIcon}
                                        alt=""
                                        className="me-2"
                                      />
                                      Duplicate
                                    </MenuItem>
                                    <Divider /> */}
                                    <MenuItem
                                      onClick={(e) => {
                                        setDeleteModal(true);
                                        setdropdownmanu();
                                        setdeleteId((prev) => ([...prev, item?.id]));
                                      }}
                                    >
                                      <img
                                        // src="./assets/img/delete-icon.png"
                                        src={deleteIcon}
                                        alt=""
                                        className="me-2"
                                      />
                                      {t("campaign.action.delete")}
                                    </MenuItem>
                                  </Menu>
                                )}
                              </td>
                            </tr>
                          );
                        })
                    ) : (
                      <tr>
                        <td colSpan="7">{t("noDataFound")}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="pagination mt-4 d-flex justify-content-between">
            <div className="records-per-page">
              <span className="boomio-bold purple-black">
                {capFirst(t("campaign.table.show"))}
              </span>
              <select
                className="perpage"
                name="perpage"
                id=""
                value={perPage}
                onChange={(e) => {
                  setPerPage(e.target.value);
                  setCurrentCount(1);
                  setPageNumber(1);
                }}
              >
                <option value={10}>{t("campaign.table.10")}</option>
                <option value={20}>{t("campaign.table.20")}</option>
                <option value={50}>{t("campaign.table.50")}</option>
                <option value={100}>{t("campaign.table.100")}</option>
              </select>
              <span className="boomio-bold purple-black">
                {" "}
                {t("campaign.table.entries")}
              </span>
            </div>
            <div className="go-to-page">
              <span className="boomio-bold purple-black">
                {currentCount} {t("campaign.table.of")} {totalPages}
              </span>
              <button
                disabled={currentCount === 1}
                style={{ opacity: currentCount === 1 ? "0.5" : "1" }}
                className="paginationBtn"
                onClick={previousPage}
              >
                <img src="./assets/img/table-left-arrow.png" alt="" />
              </button>
              <button
                className="paginationBtn"
                style={{ opacity: currentCount === totalPages ? "0.5" : "1" }}
                disabled={currentCount === totalPages}
                onClick={nextPage}
              >
                <img src="./assets/img/table-right-arrow.png" alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {modalShow && (
        <AddCustomer
          type={modelType}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
          <ConfirmDialog open={deleteModal} onClose={ handleDialogClose} img={exclamationCircle}  boldText={'Are you sure delete this campaign?'} text={'This action cannot be undone'} cancelText={'Cancel'} onCancel={()=>{setDeleteModal(false)}} actionText={'Delete'} onAction={handleDelete}/>

    </>
  );
};

export default Campaigns;
