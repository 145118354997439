import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiDelete, ApiGet, ApiPatch, ApiPost } from "helper/API/ApiData";
import { CampaignBaseUrl } from "helper/API/BaseUrl";

export const checkProductUrl = createAsyncThunk(
  "campaigns/createCampaigns",
  async (data) => {
    return await ApiPost(
      `${CampaignBaseUrl}campaign/check-url-availability`,
      data
    )
      .then((res) => {
        return Response;
      })
      .catch((err) => {
        err = { isError: true };
        return err;
      });
  }
);

export const createCampaigns = createAsyncThunk(
  "campaigns/createCampaigns",
  async (data, thunkAPI) => {
    return await ApiPost(`${CampaignBaseUrl}campaign`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        err = { isError: true };
        return err;
      });
  }
);

export const editCampaigns = createAsyncThunk(
  "campaigns/createCampaigns",
  async (data, thunkAPI) => {
    const id = data.id;
    return await ApiPatch(`${CampaignBaseUrl}campaign/${id}`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        err = { isError: true };
        return err;
      });
  }
);

export const getCampaigns = createAsyncThunk(
  "campaigns/getCampaigns",
  async (data, thunkAPI) => {
    const page = data?.[0];
    const limit = data?.[1];
    return await ApiGet(
      `${CampaignBaseUrl}campaign/?page=${page}&limit=${limit}`
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        err = { isError: true };
        return err;
      });
  }
);

export const getCampaignsById = createAsyncThunk(
  "campaigns/getCampaigns",
  async (id, thunkAPI) => {
    return await ApiGet(`${CampaignBaseUrl}campaign/${id}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        err = { isError: true };
        return err;
      });
  }
);

export const deleteCampaignsById = createAsyncThunk(
  "campaigns/getCampaigns",
  async (id, thunkAPI) => {
    return await ApiDelete(`${CampaignBaseUrl}campaign/${id}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        err = { isError: true };
        return err;
      });
  }
);

export const campaignSlice = createSlice({
  name: "campaignSlice",
  initialState: { entities: [], loading: false },
  reducers: {},
  extraReducers: {
    [createCampaigns.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.value += action.payload;
    },
    [createCampaigns.reject]: (state, action) => {
      state.isLoading = false;
    },
    [createCampaigns.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getCampaigns.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.value += action.payload;
    },
    [getCampaigns.reject]: (state, action) => {
      state.isLoading = false;
    },
    [getCampaigns.pending]: (state, action) => {
      state.isLoading = true;
    },
  },
});

export default campaignSlice.reducer;
