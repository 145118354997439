import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ApiGet, ApiPatch } from "helper/API/ApiData"
import { BusinessBaseUrl } from "helper/API/BaseUrl"

export const getBusinessDetails = createAsyncThunk(
    'business/get',
    async (data, thunkAPI) => {
        return await ApiGet(`${BusinessBaseUrl}business/get/self`).then((res) => {
            return res?.response;
        }).catch((err) => {
            err = { isError: true }
            return err
        })
    }
)

export const patchBusinessData = createAsyncThunk(
    'business/update',
    async (data, thunkAPI) => {
        return await ApiPatch(`${BusinessBaseUrl}business/update/self`, data).then((res) => {
            return res?.data?.response;
        }).catch((err) => {
            err = { isError: true }
            return err
        })
    }
)

export const businessSlice = createSlice({
    name: "businessSlice",
    initialState: { data: {}, isLoading: false },
    reducers: {
    },
    extraReducers: {
        [getBusinessDetails.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        [getBusinessDetails.reject]: (state, action) => {
            state.isLoading = false;
        },
        [getBusinessDetails.pending]: (state, action) => {
            state.isLoading = true;
        },
        [patchBusinessData.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        [patchBusinessData.reject]: (state, action) => {
            state.isLoading = false;
        },
        [patchBusinessData.pending]: (state, action) => {
            state.isLoading = true;
        },
    },

})

export default businessSlice.reducer;