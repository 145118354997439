import React from 'react';
import ReactDOM from 'react-dom';
import './assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './helper/i18n';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import store from 'redux/store';
import { Provider } from 'react-redux';
import { appSettings } from 'helper/appSettings';

const stripeTestPromise = loadStripe(appSettings.STRIPE_PK)

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={"loading...."}>
      <Provider store={store}>
        <BrowserRouter>
          <Elements stripe={stripeTestPromise}>
            <App />
          </Elements>
        </BrowserRouter>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
