import { Box, ImageList, ImageListItem } from '@mui/material';
import React from 'react';
import { Button, Dropdown, Modal } from 'react-bootstrap';

const ImageGallery = (props) => {

    const itemData = [
        {
            img: './assets/img/pexels-photo-pizza-8.jpeg',
        },
        {
            img: '../assets/img/sarang-pande-5djt8Pkqdc4-unsplash.png',
        },
        {
            img: './assets/img/pexels-photo-pizza-7.jpeg',
        },
        {
            img: './assets/img/pexels-photo-pizza-2.jpeg',
        },
        {
            img: './assets/img/pexels-photo-pizza-6.jpeg',
        },
        {
            img: './assets/img/pexels-photo-pizza-3.jpeg',
        },
        {
            img: './assets/img/pexels-photo-pizza-4.jpeg',
        },
        {
            img: './assets/img/pexels-photo-pizza-5.jpeg',
        },
        {
            img: './assets/img/pexels-photo-pizza-12.jpeg',
        },
        {
            img: './assets/img/pexels-photo-pizza-10.jpeg',
        },
        {
            img: './assets/img/pexels-photo-pizza-11.jpeg',
        },
        {
            img: './assets/img/pexels-photo-pizza-9.jpeg',
        },
    ];

    return (
        <>
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='image-gallery'
            // onShow={props.hideAnother}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <img src="./assets/img/sidebar-logo.png" alt="" className='me-3' />
                        Image Gallery
                        <div className='d-flex mx-md-4 position-relative justify-content-between'>
                            <div className='search'>
                                <input
                                    type="text"
                                    value=""
                                    name='Search'
                                    onChange={() => { }}
                                    className="search-input"
                                    placeholder='Search'
                                />
                                <img src="./assets/img/search-icon.png" alt="" />
                            </div>
                        </div>
                        <Button className="add-new-btn me-5" onClick={props.onHide}>Add New</Button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex mb-3 image-gallery-dropdowm'>
                        <div className='d-md-flex'>
                            <Dropdown className="">
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    All Categories
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">
                                        All Categories
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                        Food
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                        Drinks
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-4">
                                        Celebrations
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-4">
                                        Other
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown className="">
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    Recently Used
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">
                                        Edit Profile
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                        Settings
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                        Support
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-4">
                                        sign Out
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className='d-md-flex'>
                            <Dropdown className="">
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    Uploads
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">
                                        Edit Profile
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                        Settings
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                        Support
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-4">
                                        sign Out
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown className="">
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    Selected
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">
                                        Edit Profile
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                        Settings
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                        Support
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-4">
                                        sign Out
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <Box sx={{ width: 500, height: 450, overflowY: 'scroll' }}>
                        <ImageList variant="masonry" cols={3} gap={8}>
                            {itemData.map((item) => (
                                <ImageListItem key={item.img}>
                                    <img
                                        src={`${item.img}?w=248&fit=crop&auto=format`}
                                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                        alt=''
                                        loading="lazy"
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Box>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ImageGallery;
