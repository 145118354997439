import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import checkmark from "../../../assets/img/checkmark.png";
import ArrowDown from "../../../assets/img/arrowDown.png";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import GamePaper from "./GamePaper";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DateRangeSelect from "common/DateRangeSelect/DateRangeSelect";
import moment from "moment/moment";
import datepicker from "../../../assets/img/date-picker.png";
import queryString from "query-string";
import TimeSelect from "./TimeSelect";
import ArrowDownColored from "../../../assets/img/ArrowDownColored.png";
import RewardPaper from "./RewardPaper";
import StorePaper from "./StorePaper";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={1} square {...props} />
))(({ theme }) => ({
  border: `1px solid red`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },

  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const CampaignAccordion = ({
  urlFields,
  setUrlFields,
  rewardType,
  setRewardType,
  couponData,
  setCouponData,
  discountAmount,
  setDiscountAmount,
  selectedDay,
  setSelectedDay,
  days,
  setDays,
  dateRange,
  setDateRange,
  campaignData,
  setCampaignData,
  shopName,
  shopConnected,
  setShopConnected,
  gameSelect,
  setGameSelect,
  gameLoop,
  setGameLoop,
  firstShopConnected,
  campaignId,
  expanded,
  setExpanded,
}) => {
  const query = queryString.parse(window.location.search);

  const id = query?.id;

  const [open, setOpen] = React.useState(false);
  const [focusedRange, setFocusedRange] = React.useState(null);
  const [moreGames, setMoreGames] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const productRewardSchema = yup.object({
    Specific_product_URL: yup
      .array()
      .of(
        yup
          .string()
          .required("Product URL is required.")
          .matches(
            /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
            "Enter valid product URL"
          )
      )
      .required("Product URL is required."),
  });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    reset: reset1,
    formState: { errors },
    clearErrors: clearErrors1,
  } = useForm({
    resolver: yupResolver(productRewardSchema),
  });

  const dateRangeChange = (ranges) => {
    if (ranges && ranges.range1.startDate && ranges.range1.endDate) {
      const startDate = moment(ranges.range1?.startDate).format("YYYY/MM/DD");
      const endDate = moment(ranges.range1?.endDate).format("YYYY/MM/DD");
      setValue1("campaignStartDate", startDate);
      setValue1("campaignEndDate", endDate);
      ranges.range1?.endDate && clearErrors1("campaignEndDate");
      setDateRange({ startDate, endDate });
    }
  };
  const toggle = (range) => {
    setFocusedRange(range);
    setOpen(!open);
  };

  // console.log(selectedDay !== 0 || (days && days !== ""));
  // console.log(selectedDay, days);

  return (
    <>
      <DateRangeSelect
        startDate={dateRange?.startDate}
        endDate={dateRange?.endDate}
        open={open}
        setOpen={setOpen}
        dateRangeChange={dateRangeChange}
        focusedRange={focusedRange}
        setFocusedRange={setFocusedRange}
      />

      <div className="campagin-accordion-container">
        <Accordion
          className={`${
            gameSelect.value !== '' && expanded !== "panel1"
              ? "campagin-accordion campagin-accordion-active"
              : "campagin-accordion"
          }`}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<img src={ArrowDown} className="expand_icon" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="campagin-accordion-summary"
          >
            <Typography>
              <label
                htmlFor=""
                className="input-bold-bigger"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {gameSelect.value !== '' && expanded !== "panel1" ? (
                  <img
                    style={{ marginRight: "10px", maxHeight: "20px" }}
                    src={checkmark}
                  />
                ) : (
                  <span className="campaign-list">1.</span>
                )}
                Select a game for visitors to play on your e-shop{" "}
              </label>
            </Typography>
          </AccordionSummary>
          <div className="gradient-div"></div>

          <AccordionDetails className="campagin-accordion-details">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <GamePaper
                gameSelect={gameSelect}
                setGameSelect={setGameSelect}
              />
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "40px",
                }}
                onClick={() => setMoreGames(!moreGames)}
              >
                <p
                  className="game-paper-header"
                  style={{
                    cursor: "pointer !important",
                    alignItems: "center",
                    fontSize: "12px",
                    marginBottom: "8px",
                  }}
                >
                  View more games
                </p>
                <img
                  src={ArrowDownColored}
                  className={moreGames ? "more_games_open" : "more_games"}
                ></img>
              </div> */}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      {/* ****************** hidden url based campaign  ******************/}

      {/* <div className="campagin-accordion-container">
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          className={`${
            (urlFields.some((element) => element.value !== "") ||
              gameLoop === "time") &&
            expanded !== "panel2"
              ? "campagin-accordion campagin-accordion-active"
              : "campagin-accordion"
          }`}
        >
          <AccordionSummary
            expandIcon={<img src={ArrowDown} className="expand_icon" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="campagin-accordion-summary"
          >
            <Typography>
              <label
                htmlFor=""
                className="input-bold-bigger"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {(urlFields.some((element) => element.value !== "") ||
                  gameLoop === "time") &&
                expanded !== "panel2" ? (
                  <img
                    style={{ marginRight: "10px", maxHeight: "20px" }}
                    src={checkmark}
                  />
                ) : (
                  <span className="campaign-list">2.</span>
                )}
                Determine how the game will be displayed{" "}
              </label>{" "}
              {expanded === "panel2" && (
                <p className="campaign-list-subtext">
                  Consider your products, audience, and e-shop experience to
                  choose the best option for your goals.
                </p>
              )}
            </Typography>
          </AccordionSummary>{" "}
          <div className="gradient-div"></div>
          <AccordionDetails className="campagin-accordion-details">
            <TimeSelect
              gameLoop={gameLoop}
              setGameLoop={setGameLoop}
              urlFields={urlFields}
              setUrlFields={setUrlFields}
              gameSelect={gameSelect}
            />
          </AccordionDetails>
        </Accordion>
      </div> */}
      <div className="campagin-accordion-container">
        <Accordion
          className={`${
            ((selectedDay && selectedDay !== 0) ||
              (days && days !== "" && days !== 0)) &&
            expanded !== "panel3"
              ? "campagin-accordion campagin-accordion-active"
              : "campagin-accordion"
          }`}
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<img src={ArrowDown} className="expand_icon" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="campagin-accordion-summary"
          >
            <Typography>
              <label
                htmlFor=""
                className="input-bold-bigger"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {((selectedDay && selectedDay !== 0) ||
                  (days && days !== "" && days !== 0)) &&
                expanded !== "panel3" ? (
                  <img
                    style={{ marginRight: "10px", maxHeight: "20px" }}
                    src={checkmark}
                  />
                ) : (
                  <span className="campaign-list">2.</span>
                )}
                Add the details of the rewards to be offered to visitors{" "}
              </label>
            </Typography>
          </AccordionSummary>{" "}
          <div className="gradient-div"></div>
          <AccordionDetails className="campagin-accordion-details">
            <RewardPaper
              rewardType={rewardType}
              setRewardType={setRewardType}
              couponData={couponData}
              setCouponData={setCouponData}
              discountAmount={discountAmount}
              setDiscountAmount={setDiscountAmount}
              selectedDay={selectedDay}
              setSelectedDay={setSelectedDay}
              days={days}
              setDays={setDays}
            />
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="campagin-accordion-container">
        <Accordion
          className={`${
            dateRange?.endDate && expanded !== "panel4"
              ? "campagin-accordion campagin-accordion-active"
              : "campagin-accordion"
          }`}
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<img src={ArrowDown} className="expand_icon" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="campagin-accordion-summary"
          >
            <Typography>
              <label
                htmlFor=""
                className="input-bold-bigger"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {dateRange?.endDate && expanded !== "panel4" ? (
                  <img
                    style={{ marginRight: "10px", maxHeight: "20px" }}
                    src={checkmark}
                  />
                ) : (
                  <span className="campaign-list">3.</span>
                )}
                Choose the duration of the campaign{" "}
              </label>
            </Typography>
          </AccordionSummary>{" "}
          <div className="gradient-div"></div>
          <AccordionDetails className="campagin-accordion-details">
            <div className="w-100 d-flex flex-wrap justify-content-between">
              <div>
                <div className="set-date-picker first-time-reward">
                  <div className="d-flex flex-wrap gap-2  mt-7">
                    <div className="boomio_input_main w-md-100 date_margin full_width_small">
                      <div
                        className="boomio_input boomio_datepicker"
                        style={{
                          position: "relative",
                          paddingRight: "50px",
                          minWidth: "250px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        onClick={() => toggle([0, 0])}
                      >
                        <input
                          type={"hidden"}
                          {...register1("campaignStartDate")}
                        />
                        {`${
                          dateRange?.startDate
                            ? dateRange?.startDate
                            : "Set Start Date"
                        } 
                      `}
                        <img
                          src={datepicker}
                          alt=""
                          onClick={() => toggle([0, 0])}
                        />
                      </div>
                    </div>
                    <div className="boomio_input_main w-sm-100 full_width_small">
                      <div
                        className={
                          dateRange?.endDate
                            ? "boomio_input boomio_datepicker"
                            : "boomio_input_active boomio_datepicker"
                        }
                        style={{
                          position: "relative",
                          paddingRight: "50px",
                          minWidth: "250px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        onClick={() => toggle([0, 1])}
                        // style={{
                        //   background: "none",
                        //   webkitBackgroundClip: "none",
                        //   color: "red",
                        // }}
                      >
                        <input
                          type={"hidden"}
                          {...register1("campaignEndDate")}
                        />

                        {`
    ${dateRange?.endDate ? dateRange?.endDate : "Set End Date"} `}

                        <img
                          src={datepicker}
                          alt=""
                          onClick={() => toggle([0, 1])}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors?.campaignEndDate && (
                  <span className="campaings_error">
                    {errors?.campaignEndDate?.message}
                  </span>
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      {!firstShopConnected && !campaignId && (
        <div className="campagin-accordion-container">
          <Accordion
            className={`${
              shopConnected && expanded !== "panel5"
                ? "campagin-accordion campagin-accordion-active"
                : "campagin-accordion"
            }`}
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<img src={ArrowDown} className="expand_icon" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="campagin-accordion-summary"
            >
              <Typography>
                <label
                  htmlFor=""
                  className="input-bold-bigger"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  {shopConnected && expanded !== "panel5" ? (
                    <img
                      style={{ marginRight: "10px", maxHeight: "20px" }}
                      src={checkmark}
                    />
                  ) : (
                    <span className="campaign-list">4.</span>
                  )}
                  Connect your e-shop to Boomio account
                </label>
              </Typography>
            </AccordionSummary>
            <div className="gradient-div"></div>
            <AccordionDetails className="campagin-accordion-details">
              <div className="w-100 d-flex flex-wrap justify-content-between">
                <StorePaper
                  shopConnected={shopConnected}
                  setShopConnected={setShopConnected}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </>
  );
};

export default CampaignAccordion;
