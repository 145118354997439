import React from "react";
import { Button, Modal } from "react-bootstrap";
import lady_with_baloons_img from "../../../assets/img/baloons.png";
const AddStoreOne = ({ show, onHide, onProceed, name }) => {
  return (
    <Modal
      name={name}
      show={show}
      onHide={onHide}
      onProceed={onProceed}
      backdrop="static"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      className="initial_add_store_modal"
      style={{ width: "100% !important" }}
    >
      <Modal.Body className="initModal">
        <div className="initial_add_store_main">
          <div className="image">
            <img
              className="initial_add_store_modal_img"
              src={lady_with_baloons_img}
              alt=""
            />
          </div>
          <div className="text">
            <h1 className="header">Welcome, {name}</h1>
            <p className="sub_header">
              Well done on taking your first step with Boomio!
            </p>
            <p>
              Get ready to create customer loyalty and drive your e-shop's
              growth! With engaging rewards, and gamified experiences, your
              customers will keep coming back for more.
            </p>
            <p>Start boosting loyalty and growth with Boomio today!</p>
            <div style={{ flexDirection: "row", width: "100%" }}>
              <Button
                onClick={onProceed}
                className="button-8559F3 boomio-white mx-3"
              >
                Start Campaign
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddStoreOne;
