import React, { useState, useEffect } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker, createStaticRanges } from "react-date-range";
import Modal from "@mui/material/Modal";
import { Button } from "react-bootstrap";

const DateRangeSelect = ({
  startDate,
  endDate,
  open,
  setOpen,
  dateRangeChange,
  focusedRange,
  setFocusedRange,
  minDate,
}) => {
  const staticRanges = createStaticRanges([
    {
      label: "Today",
      range: () => ({
        startDate: new Date(),
        endDate: new Date(),
      }),
    },
    {
      label: "This Week",
      range: () => ({
        startDate: new Date(),
        endDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + ((7 - new Date().getDay()) % 7)
        ),
      }),
    },
    {
      label: "This Month",
      range: () => ({
        startDate: new Date(),
        endDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ),
      }),
    },
    {
      label: "3 Days",
      range: () => ({
        startDate: new Date(),
        endDate: new Date(new Date().setDate(new Date().getDate() + 3)),
      }),
    },
    {
      label: "7 Days",
      range: () => ({
        startDate: new Date(),
        endDate: new Date(new Date().setDate(new Date().getDate() + 7)),
      }),
    },
    {
      label: "30 Days",
      range: () => ({
        startDate: new Date(),
        endDate: new Date(new Date().setDate(new Date().getDate() + 30)),
      }),
    },
  ]);

  useEffect(() => {
    setSelectedRange({
      startDate: startDate ? new Date(startDate) : new Date(),
      endDate: endDate ? new Date(endDate) : new Date(),
    });
  }, [startDate, endDate]);

  const [selectedRange, setSelectedRange] = useState({
    startDate: startDate ? new Date(startDate) : null,
    endDate: endDate ? new Date(endDate) : null,
  });

  const toggle = () => {
    setOpen(!open);
  };

  const handleInputChange = (ranges) => {
    setSelectedRange(ranges.range1);
    dateRangeChange(ranges);
  };

  const focusedRangeChange = () => {
    setFocusedRange(focusedRange ? [0, focusedRange[1] === 1 ? 0 : 1] : [0, 1]);
  };

  return (
    <Modal open={open} onClose={toggle}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          left: "50%",
          top: "50%",
          flexDirection: "column",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "32px",
          borderRadius: "16px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <DateRangePicker
          onRangeFocusChange={focusedRangeChange}
          startDate={new Date()}
          endDate={undefined}
          onChange={handleInputChange}
          months={1}
          ranges={[selectedRange]}
          direction="horizontal"
          minDate={minDate === 0 ? undefined : new Date()}
          focusedRange={focusedRange ?? [0, 0]}
          staticRanges={staticRanges}
        />
        {open && (
          <div
            style={{
              gap: "12px",
              paddingTop: "2px",
              width: "100%",
              display: "flex",
              justifyContent: "start",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "330px",
                justifyContent: "center",
                margin: "0px 12px",
                gap: "20px",
              }}
            >
              <Button
                className="outline_btn_dark me-2"
                onClick={() => setOpen(false)}
                style={{
                  marginTop: "24px",
                  width: "170px",
                  fontweight: "600 !important",
                  height: "42px",
                }}
              >
                <span style={{ fontWeight: "600 !important" }}>Cancel</span>
              </Button>
              <Button
                className="dateTimeButton"
                onClick={() => setOpen(false)}
                style={{
                  marginTop: "24px",
                  width: "170px",
                  fontweight: "600 !important",
                  height: "42px",
                }}
              >
                <span>Apply</span>
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DateRangeSelect;
