import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { ApiGet, ApiPost } from "helper/API/ApiData";
import { MenuItem, Select } from "@mui/material";
import { useFormik } from "formik";
import upload from "../../assets/img/upload.svg";
import * as Yup from "yup";
import ContinueButton from "common/Button";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { patchBusinessData } from "redux/features/business/businessSlice";
import { toast } from "react-toastify";
import { FileBaseUrl, InfoBaseUrl } from "helper/API/BaseUrl";
import Button from "@material-ui/core/Button";
import { Modal } from "react-bootstrap";
import { UserBaseUrl } from "helper/API/BaseUrl";
import copyIcon from '../../assets/img/copy-icon.png';
import exclamationCircle from "../../assets/img/ExclamationCircle.png";
import Dialog from "@mui/material/Dialog";

const EditProfile = () => {
  const dispatch = useDispatch();
  const businessState = useSelector((state) => state.businessState.data);
  const formInitialState = {
    name: "",
    email: "",
    phone: "",
    ownerName: "",
    countryId: "",
    postalCode: "",
    address: "",
    logo: "",
    www: "",
    store: "",
    description: "",
    stateId: "",
    category: "",
    city: "",
  };

  const inputraf = useRef(null);
  const [countries, setCountries] = useState([]);
  const [usStates, setUSstates] = useState([]);
  const [logoImage, setLogoImage] = useState({ logoFormData: null, url: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [img, steImg] = useState("");
  const [selectChanged, setSelectChanged] = useState(false);
  const [open, setOpen] = useState(false);
  const [apiToken, setApiToken] = useState('');
  const [openDialog, handleDisplay] = React.useState(false);
  const [promptOn, setPromptOn] = useState(true)


  useEffect(() => {
    ApiGet(`${InfoBaseUrl}api/country`).then((res) => {
      if (res && res.response) {
        setCountries(res.response);
      }
    });
    return () => setCountries([]);
  }, []);

  useEffect(() => {
    ApiGet(`${InfoBaseUrl}api/static_info/us_states`).then((res) => {
      if (res && res.response.json_body.us_states) {
        setUSstates(res.response.json_body.us_states);
      }
    });
    return () => setUSstates([]);
  }, []);

  const categories = ['Choose Category', 'Toys, hobby and DIY', 'Fashion', 'Furniture', 'Beauty', 'Health', 'Household care', 'Other']


  useEffect(() => {
    steImg(businessState?.logo);
    if (businessState && countries.length) {
      formik.setValues({
        name: businessState?.name || formInitialState.ownerName,
        postalCode:
          businessState?.zip_code?.toString() || formInitialState.ownerName,
        ownerName:
          businessState?.params?.extra_fields?.ownerName ||
          formInitialState.ownerName,
        email:
          businessState?.params?.extra_fields?.email || formInitialState.email,
        phone:
          businessState?.params?.extra_fields?.phone || formInitialState.phone,
        countryId:
          businessState?.params?.extra_fields?.countryId ||
          formInitialState?.countryId,
        address: businessState?.address || formInitialState.address,
        logo: businessState?.logo ?? formInitialState.logo,
        www: businessState?.www ?? formInitialState.www,
        description: businessState?.description ?? formInitialState.description,
        store: businessState?.shopify_params?.url ?? formInitialState?.store,
        stateId: businessState?.params?.extra_fields?.state ?? formInitialState?.stateId,
        category: businessState?.params?.extra_fields?.category ?? formInitialState?.category,
        city: businessState?.params?.extra_fields?.city ?? formInitialState?.city,
      });
    }
  }, [businessState, countries]);


  useEffect(() => {
    ApiGet(`${UserBaseUrl}users/business/integration-token`)
      .then((res) => {
        if (res && res.response && res.response.access_token) {
          setApiToken(res.response.access_token)
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [])

  const handleDialogClose = () => {
    handleDisplay(false);
  };



  const handleDialogExecute = () => {
    handleDisplay(false);
    generateToken();
  };

  const openDialogBox = () => {
    if (apiToken === '') {
      generateToken()
    } else {
      handleDisplay(true);
    }
  };

  const dialogBoxStyle = {
    display: "flex",
    flexDirection: "row-reverse",
    position: "absolute",
    right: "0px",
    bottom: "0px",
    justifyContent: 'space-evenly',
    width: '100%',
    height: '65px',
    paddingBottom: "20px",
  };

  const dialogTextStyle = {
    marginTop: '-40px',
    padding: '50px 50px 80px 50px',
    maxWidth: '400px',
    color: 'black',
    textAlign: 'center',
    lineHeight: '24px',
    fontWeight: '500',
  }




  const handleConfirm = () => {
    window.open(
      `https://admin.shopify.com/store/${businessState.shopify_params.name}/settings/apps/app_installations/app/boomio`,
      "_blank"
    );
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: formInitialState,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      ownerName: Yup.string().required("Owner Name is required"),
      email: Yup.string().required("email is required"),
      phone: Yup.string().required("phone is required"),
      countryId: Yup.string().required("Please select Country"),
      postalCode: Yup.string().required("Postal Code is required"),
      address: Yup.string().required("Address is required"),
      www: Yup.string().required("Url is required"),
      description: Yup.string().required("Description is required"),
      stateId: Yup.string().required("Please select State"),
      category: Yup.string().required("Category is required").notOneOf(['Choose Category'], "Category is required"),
      city: Yup.string().required("City is required"),
    }),

    onSubmit: async (val) => {
      setIsLoading(true);
      try {
        let logoURL = img;
        if (logoImage?.logoFormData) {
          const uploadRes = await ApiPost(
            `${FileBaseUrl}file/upload`,
            logoImage?.logoFormData
          );
          const {
            response: { url },
          } = uploadRes;
          logoURL = url;
          steImg(url);
        }
        let body = {
          name: val.name,
          address: val.address,
          logo: logoURL,
          zip_code: val.postalCode,
          www: val.www,
          description: val.description,
          params: {
            extra_fields: {
              email: val.email,
              phone: val.phone,
              countryId: val.countryId,
              ownerName: val.ownerName,
              category: val.category,
              city: val.city,
              state: val.stateId,
            },
          },
          shopify_params: {
            url: val.store,
          },
        };
        updateHandler(body);
      } catch (e) {
        setIsLoading(false);
      }
    },
  });


  const postUploadfile = (file) => {
    const form_data = new FormData();
    form_data.append("file", file?.target.files[0]);
    form_data.append("group", "profile");
    form_data.append("subgroup", "");
    form_data.append("filename", new Date().getTime());
    form_data.append("url", URL.createObjectURL(file?.target.files[0]));
    setLogoImage({
      logoFormData: form_data,
      url: URL.createObjectURL(file?.target.files[0]),
    });
  };

  const updateHandler = async (postdata) => {
    dispatch(patchBusinessData(postdata))
      .then(unwrapResult)
      .then((res) => {
        setIsLoading(false);
        if (promptOn) {
          toast.success("Edit profile successfully", {
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
        setPromptOn(true)
      })
      .catch((e) => setIsLoading(false));
  };

  const updateValue = (e) => {
    const { value } = e.target;
    formik.setFieldValue("countryId", value);
    setSelectChanged(true);
  };

  const updateState = (e) => {
    const { value } = e.target;
    formik.setFieldValue("stateId", value);
    setSelectChanged(true);

  };

  const updateCategory = (e) => {
    const { value } = e.target;
    formik.setFieldValue("category", value);
    setSelectChanged(true);
  };

  const { errors, touched } = formik;
  const anyTouched =
    touched.address ||
    touched?.countryId ||
    touched?.email ||
    touched?.logo ||
    touched?.name ||
    touched?.ownerName ||
    touched?.phone ||
    touched?.city ||
    touched?.postalCode ||
    touched?.www ||
    touched?.description ||
    selectChanged ||
    logoImage?.logoFormData;

  const generateToken = () => {
    const body = {};
    ApiPost(`${UserBaseUrl}users/business/integration-token`, body)
      .then((res) => {
        if (res && res.response && res.response.access_token) {
          setApiToken(res.response.access_token)
        }
      })
      .catch((err) => {
        toast.error(err && err.response?.data?.metadata?.res_msg, {
          closeOnClick: true,
          pauseOnHover: true,
        });
      });
  };

  return (
    <>
      <Modal
        show={open}
        onHide={handleCancel}
        backdrop="static"
        centered
        aria-labelledby="example-custom-modal-styling-title"
        className="initial_add_store_modal"
        style={{ width: "100% !important" }}
      >
        <Modal.Body className="plansModal">
          <img
            onClick={handleCancel}
            src="./assets/img/closeIcon.png"
            className="closeIcon icon-size"
            alt="close"
          />
          <div
            className="initial_add_store_main"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h1 className="header">Confirmation</h1>
            <p style={{ marginBottom: "40px", textAlign: "center" }}>
              Are you sure you want to delete Boomio from{" "}
              {businessState?.shopify_params?.name}?
            </p>
            <div style={{ flexDirection: "row", width: "100%" }}>
              <Button
                onClick={handleCancel}
                className="button-8559F3 boomio_input boomio_input_main mx-3"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                className="button-8559F3 boomio-white mx-3"
              >
                Confirm
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Form onSubmit={formik.handleSubmit}>
        <div className="myaccount">
          <div className="row" style={{ flexDirection: 'column' }}>
            <div className="col-12">
              <h4>Tell us more about your e-store</h4>
              <div className="set-profile d-flex">
                <img
                  src={logoImage?.url ? logoImage?.url : formik.values.logo}
                  alt=""
                  width={80}
                  height={80}
                />
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={inputraf}
                  onChange={(e) => {
                    postUploadfile(e);
                  }}
                />
                <h2>{businessState?.name}</h2>
              </div>
              <div className="d-flex uplopad col-2 cursor-pointer">
                <img
                  src={upload}
                  alt=""
                  onClick={(e) => {
                    inputraf?.current?.click();
                  }}
                />
                <p
                  onClick={(e) => {
                    inputraf?.current?.click();
                  }}
                >
                  Add logo
                </p>
              </div>
            </div>

            <div className="col-12 mt-5">
              <p className="title-lable">Your e-store name <sup style={{ color: 'red' }}>*</sup></p>
              <input
                type="text"
                className="model-input"
                placeholder="Charlie Pizza"
                value={formik.values.name}
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {errors.name && (
                <Form.Text className="color">
                  {touched.name && errors.name}
                </Form.Text>
              )}
            </div>



            <div className="col-12 mt-5">
              <p className="title-lable">E-store website<sup style={{ color: 'red' }}>*</sup></p>
              <input
                type="text"
                className="model-input"
                name="www"
                placeholder="Url"
                value={formik.values.www}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {errors.www && (
                <Form.Text className="color">
                  {touched.www && errors.www}
                </Form.Text>
              )}
            </div>
            {businessState?.shopify_params && (
              <div className="col-12 mt-5">
                <p className="title-lable">E-store URL <sup style={{ color: 'red' }}>*</sup></p>
                <div className="d-flex flex-row-reverse align-items-center">
                  <input
                    style={{ flex: "auto" }}
                    type="text"
                    className="model-input disabled"
                    name="Store"
                    placeholder="Store"
                    value={formik.values.store}
                    disabled
                  />
                  <div style={{ margin: "8px 0px 0px 8px" }}>
                    <img
                      onClick={() => setOpen(true)}
                      src="../../../assets/img/closeIcon.png"
                      className="me-2 icon-size"
                      alt="close"
                    />{" "}
                  </div>
                </div>
              </div>
            )}
            {/* {!businessState?.shopify_params &&  ( */}
            {true && (
              <div className="col-12 mt-5">
                <p className="title-lable">Boomio API Token</p>
                <div className="d-flex">
                  <div style={{ flex: "auto", order: '0', cursor: 'auto', borderBottomRightRadius: '0px', borderTopRightRadius: '0px' }} className="model-input disabled">
                    {apiToken}
                  </div>
                  <div className="copy-icon" onClick={() => navigator.clipboard.writeText(apiToken)} ><img style={{ width: '20px' }} src={copyIcon} alt="" /> </div>
                  <button className="token-generator-button"
                    onClick={() => { setPromptOn(false); openDialogBox() }}> Generate Token </button>
                </div>
              </div>
            )}
            <Dialog onClose={handleDialogClose} open={openDialog}>
              <img src={exclamationCircle} alt="" style={{ width: "30px", margin: '20px auto auto auto', marginTop: '20px', }} />
              <span style={dialogTextStyle} >{'This will replace your existing token and can harm your shop connectivity.'} <br></br> {'Do you want to continue?'} </span>
              <div style={dialogBoxStyle}>
                <button style={{ width: '150px' }} className={"outline_btn_dark btn btn-primary"} onClick={handleDialogExecute}>
                  <span> {'Continue'} </span>
                </button>
                <button style={{ width: '150px' }} className={"outline_btn_dark btn btn-primary"} onClick={handleDialogClose}>
                  <span> {'Cancel'} </span>
                </button>
              </div>
            </Dialog>

            <div className="col-6 mt-5">
              <p className="title-lable">E-store category <sup style={{ color: 'red' }}>*</sup></p>

              <Select
                className="selectbox"
                name="countryId"
                value={formik.values.category}
                onChange={updateCategory}
                displayEmpty
                style={{ width: "100%" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {categories &&
                  categories?.length &&
                  categories?.map((category) => (
                    <MenuItem
                      key={category}
                      value={category}
                    >{category}</MenuItem>
                  ))}
              </Select>
              {errors.category && (
                <Form.Text className="color">
                  {touched.category && errors.category}
                </Form.Text>
              )}
            </div>
            <div className="col-6 mt-5">
              <p className="title-lable">Country <sup style={{ color: 'red' }}>*</sup></p>
              <Select
                className="selectbox"
                name="countryId"
                value={formik.values.countryId}
                onChange={updateValue}
                displayEmpty
                style={{ width: "100%" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {countries &&
                  countries?.length &&
                  countries?.map((country) => (
                    <MenuItem
                      key={country.country_code}
                      value={country?.country_code}
                    >{`${country?.country_code} - ${country.name}`}</MenuItem>
                  ))}
              </Select>
              {errors.countryId && (
                <Form.Text className="color">
                  {touched.countryId && errors.countryId}
                </Form.Text>
              )}
            </div>

            {formik.values.countryId === 'US' && <div className="col-6 mt-5">
              <p className="title-lable">State <sup style={{ color: 'red' }}>*</sup></p>
              <Select
                className="selectbox"
                name="stateId"
                value={formik.values.stateId}
                onChange={updateState}
                displayEmpty
                style={{ width: "100%" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {usStates &&
                  usStates?.length &&
                  usStates?.map((state) => (
                    <MenuItem
                      key={state.code}
                      value={state?.code}
                    >{`${state?.code} - ${state?.title}`}</MenuItem>
                  ))}
              </Select>
              {errors.stateId && (
                <Form.Text className="color">
                  {touched.stateId && errors.stateId}
                </Form.Text>
              )}
            </div>}

            <div className="col-12 mt-5">
              <p className="title-lable">City <sup style={{ color: 'red' }}>*</sup></p>
              <input
                type="text"
                className="model-input"
                name="city"
                placeholder="City"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {errors.city && (
                <Form.Text className="color">
                  {touched.city && errors.city}
                </Form.Text>
              )}
            </div>
          </div>

          <ContinueButton
            text="Save"
            arrowVisible={false}
            disabled={isLoading}
          />
        </div>
      </Form>
    </>
  );
};
export default EditProfile;
