import React, { useEffect, useState } from "react";
import RadioButton from "../../../common/RadioButton";
import Close_button from "../../../assets/img/close_button.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Col, Row } from "react-bootstrap";
import Slider from "./Slider";
import select_arrow from "../../../assets/img/boomio_select_icon.png";
import NumberInputField from "common/NumberInputField";
import SliderDays from "./SliderDays";

const RewardPaper = ({
  rewardType,
  setRewardType,
  couponData,
  setCouponData,
  discountAmount,
  setDiscountAmount,
  selectedDay,
  setSelectedDay,
  days,
  setDays,
}) => {
  const productRewardSchema = yup.object({
    Specific_product_URL: yup
      .array()
      .of(
        yup
          .string()
          .required("Product URL is required.")
          .matches(
            /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
            "Enter valid product URL"
          )
      )
      .required("Product URL is required."),
  });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    reset: reset1,
    formState: { errors },
    clearErrors: clearErrors1,
  } = useForm({
    resolver: yupResolver(productRewardSchema),
  });

  return (
    <>
      <div className="w-100">
        <div
          style={{ display: "flex", width: "100%" }}
          className="w-100 time-select discount-select"
        >
          <div className="radio-Button">
            <RadioButton
              checked={rewardType === "FREE_SHIPPING"}
              text={"Free Shipping"}
              id={"option1"}
              value={"FREE_SHIPPING"}
              {...register1("selectRewardType1")}
              width={"100%"}
              ref={null}
              onChange={() => {
                setCouponData((prev) => ({
                  ...prev,
                  params: {
                    ...prev?.params,
                    topTitle: "YOU GOT A FREE SHIPPING!",
                    couponCodeDiscount: `Free`,
                    couponCodediscountText: "SHIPPING",
                  },
                }));
                setValue1("selectRewardType1", "FREE_SHIPPING");
                setRewardType("FREE_SHIPPING");
              }}
            />
          </div>
          <div className="radio-Button">
            <RadioButton
              width={"100%"}
              checked={rewardType === "DISCOUNT_PERCENTAGE"}
              text={"Discount %"}
              id={"option2"}
              value={"DISCOUNT_PERCENTAGE"}
              {...register1("selectRewardType1")}
              ref={null}
              onChange={() => {
                setCouponData((prev) => ({
                  ...prev,
                  params: {
                    ...prev?.params,
                    topTitle: `YOU GOT A ${discountAmount}% DISCOUNT!`,
                    couponCodeDiscount: `${discountAmount}%`,
                    couponCodediscountText: "Discount",
                  },
                }));
                setValue1("selectRewardType1", "DISCOUNT_PERCENTAGE");
                setRewardType("DISCOUNT_PERCENTAGE");
              }}
            />{" "}
          </div>{" "}
          <div className="radio-Button">
            <RadioButton
              width={"100%"}
              checked={rewardType === "DISCOUNT_CURRENCY"}
              text={"Discount $"}
              id={"option3"}
              value={"DISCOUNT_CURRENCY"}
              {...register1("selectRewardType1")}
              ref={null}
              onChange={() => {
                setCouponData((prev) => ({
                  ...prev,
                  params: {
                    ...prev?.params,
                    topTitle: `YOU GOT A ${discountAmount}$ DISCOUNT!`,
                    couponCodeDiscount: `${discountAmount}$`,
                    couponCodediscountText: "Discount",
                  },
                }));
                setValue1("selectRewardType1", "DISCOUNT_CURRENCY");
                setRewardType("DISCOUNT_CURRENCY");
              }}
            />{" "}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
        className="time_select_body"
      >
        <div
          className="d-flex flex-wrap"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="radio-Button">
            <div className="mt-4 mr-12 w-100">
              <label htmlFor="" className="campaign-reward-subtext">
                Select a reward expiration date
              </label>
            </div>
            <SliderDays
              setValue1={setValue1}
              setSelectedDay={setSelectedDay}
              selectedDay={selectedDay}
            />
          </div>
          <div>
            <div className="mt-2 mb-2">
              <label htmlFor="" className="campaign-reward-subtext">
                or enter a number of days
              </label>
            </div>
            <div
              className={
                selectedDay !== 0
                  ? "boomio_input_main boomio_input_main_disabled"
                  : "boomio_input_main"
              }
              style={{ width: "210px" }}
            >
              <NumberInputField
                {...register1("numberOfDays")}
                disabled={selectedDay !== 0}
                ref={null}
                value={days}
                setdays={setDays}
              />
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {rewardType === "DISCOUNT_PERCENTAGE" && (
            <div
              style={{ display: "flex", flexDirection: "column" }}
              className="radio-Button"
            >
              <div>
                <div className="mt-4 mr-12 w-100">
                  <label htmlFor="" className="campaign-reward-subtext">
                    Discount Amount{" "}
                  </label>
                  <Slider
                    type={"%"}
                    setValue1={setValue1}
                    setDiscountAmount={setDiscountAmount}
                    discountAmount={discountAmount}
                    setCouponData={setCouponData}
                  />
                </div>
              </div>
              <div className="">
                <div className="mt-2 mb-2">
                  <label htmlFor="" className="campaign-reward-subtext">
                    or enter a discount
                  </label>
                </div>
                <div className="boomio_input_main" style={{ width: "210px" }}>
                  <input
                    className="boomio_input"
                    type="number"
                    placeholder="or enter a discount %"
                    aria-label="default input example"
                    value={discountAmount >= 0 ? discountAmount : 0}
                    {...register1("discountAmountage")}
                    onChange={(e) => {
                      setCouponData((prev) => ({
                        ...prev,
                        params: {
                          ...prev?.params,
                          topTitle: `YOU GOT A $${e?.target?.value} DISCOUNT!`,
                          couponCodeDiscount: `$${e?.target?.value}`,
                        },
                      }));
                      setDiscountAmount(e?.target?.value);
                    }}
                  />
                </div>
                {errors?.discountAmountage && (
                  <span className="campaings_error">
                    {errors?.discountAmountage?.message}
                  </span>
                )}
              </div>
            </div>
          )}
          {rewardType === "DISCOUNT_CURRENCY" && (
            <div
              style={{ display: "flex", flexDirection: "column" }}
              className="radio-Button"
            >
              <div>
                <div className="mt-4 mr-12 w-100">
                  <label htmlFor="" className="campaign-reward-subtext">
                    Discount Amount{" "}
                  </label>
                  <Slider
                    type={"$"}
                    setValue1={setValue1}
                    setDiscountAmount={setDiscountAmount}
                    discountAmount={discountAmount}
                    setCouponData={setCouponData}
                  />
                </div>
              </div>
              <div className="">
                <div className="mt-2 mb-2">
                  <label htmlFor="" className="campaign-reward-subtext">
                    or enter a discount
                  </label>
                </div>
                <div className="boomio_input_main" style={{ width: "210px" }}>
                  <input
                    className="boomio_input"
                    type="number"
                    placeholder="or enter a discount $"
                    aria-label="default input example"
                    value={discountAmount >= 0 ? discountAmount : 0}
                    onChange={(e) => {
                      setCouponData((prev) => ({
                        ...prev,
                        params: {
                          ...prev?.params,
                          topTitle: `YOU GOT A $${e?.target?.value} DISCOUNT!`,
                          couponCodeDiscount: `$${e?.target?.value}`,
                        },
                      }));
                      setDiscountAmount(e?.target?.value);
                    }}
                  />
                </div>
                {errors?.discountDollar && (
                  <span className="campaings_error">
                    {errors?.discountDollar?.message}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RewardPaper;
