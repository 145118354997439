import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import moment from "moment/moment";
import RadioButton from "../../../common/RadioButton";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createCampaigns,
  editCampaigns,
  checkProductUrl,
  getCampaignsById,
} from "redux/features/campaigns/campaignSlice";
import queryString from "query-string";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import ReactGA from "react-ga4";
import Accordion from "./Accordion";
import ActiveChip from "./ActiveChip";
import HowItWorks from "./HowItWorks";
import { Modal } from "react-bootstrap";
import Close_button_Colored from "../../../assets/img/Close_button_Colored.png";
import UserJourney from "../userJourney/UserJourney";
import { getBusinessDetails } from "redux/features/business/businessSlice";
import exclamation from "../../../assets/img/exclamation.png";
import { Checkbox } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import checkIcon1 from "../../../assets/img/octicon_checkbox-245.svg";
import checkIcon from "../../../assets/img/octicon_checkbox-24.svg";

const SpecificProductReward = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleCancel = () => {
    setOpen(false);
  };
  const [rewardType, setRewardType] = useState("FREE_SHIPPING");
  const [discountAmount, setDiscountAmount] = useState(40);
  const [days, setDays] = useState("");
  const [selectedDay, setSelectedDay] = useState(0);
  const [activatedBoomio, setActivatedBoomio] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [showHowItWorks, setShowHowItWorks] = useState(false);
  const data = useSelector(
    (state) => state.authState.dashboardData?.shopify_params?.url
  );

  const shopName = useSelector(
    (state) => state.authState.dashboardData?.shopify_params?.type
  );
  const [emailCollectionRequired, setEmailCollectionRequired] = useState(true);
  const [shopConnected, setShopConnected] = useState(shopName);
  const [firstShopConnected, setFirstShopConnected] = useState(true);
  // set default gameLoop value tu 'url' when unhiding url based campaigns
  // const [gameLoop, setGameLoop] = React.useState("url");
  const [gameLoop, setGameLoop] = React.useState("time");

  // const [gameSelect, setGameSelect] = React.useState({
  //   value: "ice",
  //   label: "Break the Ice",
  // });
  const [gameSelect, setGameSelect] = React.useState({
      value: "",
      label: "",
    });
  const [dateRange, setDateRange] = useState({
    startDate: moment(new Date()).format("YYYY/MM/DD"),
    endDate: undefined,
  });
  const [campaignData, setCampaignData] = useState();
  const [isActive, setIsActive] = useState(false);
  const [expanded, setExpanded] = React.useState("panel1");
  useEffect(() => {
    dispatch(getBusinessDetails()).then((result) => {
      if (
        result.payload.shopify_params &&
        Object.keys(result.payload.shopify_params).length > 0 &&
        result.payload.shopify_params.status !== "Not Installed" &&
        result.payload.shopify_params.status !== "Uninstalled" &&
        result.payload.shopify_params.status !== "NOT_INSTALLED"
      ) {
        setFirstShopConnected(true);
      } else {
        setFirstShopConnected(false);
      }
    });
  }, []);

  useEffect(() => {
    setShopConnected(shopName || "");
  }, [shopName]);

  const [couponData, setCouponData] = useState({
    params: {
      topTitle: "YOU GOT A FREE SHIPPING!",
      couponCodeDiscount: `Free`,
      couponCodediscountText: "SHIPPING",
    },
  });
  const query = queryString.parse(window.location.search);
  const id = query?.id;

  const navigate = useNavigate();
  const [urlFields, setUrlFields] = useState([
    { id: 1, value: "" }, // Initial URL field
  ]);

  // const handlePreview = () => {
  //   if (data) {
  //     window.open(`${data}?demo=1`, "_blank");
  //   }
  // };
  const productRewardSchema = yup.object({
    Specific_product_URL: yup
      .array()
      .of(
        yup
          .string()
          .required("Product URL is required.")
          .matches(
            /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
            "Enter valid product URL"
          )
      )
      .required("Product URL is required."),
  });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    reset: reset1,
    formState: { errors },
    clearErrors: clearErrors1,
  } = useForm({
    resolver: yupResolver(productRewardSchema),
  });
  const handleCampaignData = (payload, status) => {
    const productUrl = urlFields
      .filter((field) => field.value !== "")
      .map((field) => field.value);
    const returnData = {
      campaign_start:
        dateRange.startDate !== "" ? dateRange.startDate : undefined,
      campaign_end: dateRange.endDate !== "" ? dateRange.endDate : undefined,
      campaign_name: campaignName
        ? campaignName
        : emailCollectionRequired === true
        ? "Collect more leads"
        : "Boost a product",
      params: {
        email_collection_required: emailCollectionRequired,
        campaignPeriod:
          payload?.campaignPeriod && parseInt(payload?.campaignPeriod),
        rewardExpirationDate: selectedDay !== 0 ? selectedDay : days,
        numberOfDays: payload?.numberOfDays && payload?.numberOfDays,
        productUrl: productUrl,
        rewardType: rewardType,
        rewardValue: discountAmount,
        campaignLaunchDate: payload?.campaignLaunchDate,
        widget_type: gameSelect.value,
      },
      id: id && id,
      campaign_type: gameLoop === "url" ? "boost_product" : "lead_to_purchase",
      status,
      // gameSelect.value === "puzzle"
      // ? "increase_browsing_time"
      // :
    };
    let urlErrorOccurred = false;
    const checkUrls = async () => {
      for (const url of productUrl) {
        if (!urlErrorOccurred) {
          const urlCheckData = {
            id: id,
            url: url,
          };
          const { payload } = await dispatch(checkProductUrl(urlCheckData));
          if (payload.isError) {
            urlErrorOccurred = true;
            toast.error(`Campaign already exists with provided url: ${url}`, {
              closeOnClick: true,
              pauseOnHover: true,
            });
            break;
          }
        }
      }

      if (!urlErrorOccurred) {
        startCampaigns();
      }
    };

    checkUrls();

    async function startCampaigns() {
      try {
        const action = id
          ? editCampaigns({ id, ...returnData })
          : createCampaigns(returnData);
        const response = await dispatch(action);
        if (response.payload?.isError) {
          throw response.payload;
        }
        ReactGA.event({
          action: "Launched campaign",
          category: "General, on selfservice",
          value: "",
        });
        navigate("/campaigns");
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };

  const getCampaignsData = async () => {
    await dispatch(getCampaignsById(id))
      .then(unwrapResult)
      .then((res) => {
        setIsActive(res?.response?.status === "inactive" ? false : true);
        setCouponData((prev) => {
          console.log("data", res?.response?.params);

          if (res?.response?.params?.rewardType === "FREE_SHIPPING") {
            return {
              ...prev,
              params: {
                ...prev?.params,
                topTitle: "YOU GOT A FREE SHIPPING!",
                couponCodeDiscount: `Free`,
                couponCodediscountText: "SHIPPING",
              },
            };
          } else if (
            res?.response?.params?.rewardType === "DISCOUNT_PERCENTAGE"
          ) {
            return {
              ...prev,
              params: {
                ...prev?.params,
                topTitle: `YOU GOT A ${
                  res?.response?.params?.rewardValue
                    ? res?.response?.params?.rewardValue
                    : 50
                }% DISCOUNT!`,
                couponCodeDiscount: `${
                  res?.response?.params?.rewardValue
                    ? res?.response?.params?.rewardValue
                    : 50
                }%`,
                couponCodediscountText: "Discount",
              },
            };
          } else if (
            res?.response?.params?.rewardType === "DISCOUNT_CURRENCY"
          ) {
            return {
              ...prev,
              params: {
                ...prev?.params,
                topTitle: `YOU GOT A ${
                  res?.response?.params?.rewardValue
                    ? res?.response?.params?.rewardValue
                    : 50
                }$ DISCOUNT!`,
                couponCodeDiscount: `${
                  res?.response?.params?.rewardValue
                    ? res?.response?.params?.rewardValue
                    : 50
                }$`,
                couponCodediscountText: "Discount",
              },
            };
          }
        });
        setRewardType(res?.response?.params?.rewardType);

        if (
          res?.response?.params?.productUrl &&
          res?.response?.params?.productUrl.length > 0
        ) {
          setUrlFields(
            res?.response?.params?.productUrl.map((url, index) => ({
              id: index + 1,
              value: url,
            }))
          );
          const newField = {
            id: res?.response?.params?.productUrl.length + 1,
            value: "",
          };
          setUrlFields((prevFields) => [...prevFields, newField]);
        }
        setDiscountAmount(
          res?.response?.params?.rewardValue
            ? res?.response?.params?.rewardValue
            : 50
        );
        setDateRange({
          startDate: moment(res?.response?.campaign_start).format("YYYY/MM/DD"),
          endDate: moment(res?.response?.campaign_end).format("YYYY/MM/DD"),
        });
        setGameSelect({
          value: res?.response?.params?.widget_type,
        });
        setGameLoop(
          res?.response?.campaign_type === "lead_to_purchase" ||
            res?.response?.campaign_type === "increase_browsing_time"
            ? "time"
            : "url"
        );
        setEmailCollectionRequired(
          res?.response?.params?.email_collection_required
        );
        setCampaignName(res?.response?.campaign_name);
        setCampaignData(res?.response);
        if (res?.response?.params?.rewardExpirationDate >= 8) {
          setDays(res?.response?.params?.rewardExpirationDate);
          setSelectedDay(0);
        } else {
          setSelectedDay(res?.response?.params?.rewardExpirationDate);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (gameSelect.value === "puzzle") {
      if (urlFields.length > 4) {
        setUrlFields(urlFields.slice(0, 4));
      } else if (urlFields.length < 4) {
        const lastId =
          urlFields.length > 0 ? urlFields[urlFields.length - 1].id : 0;
        const emptyFieldsToAdd = new Array(4 - urlFields.length)
          .fill(0)
          .map((_, index) => ({
            id: lastId + index + 1,
            value: "",
          }));
        setUrlFields(urlFields.concat(emptyFieldsToAdd));
      }
    } else {
      const updatedFields = urlFields.filter(
        (field) => field.value !== "" || field.id === 1
      );
      // Update the IDs of the remaining fields after deletion
      const updatedFieldsWithIDs = updatedFields.map((field, index) => ({
        ...field,
        id: index + 1,
      }));
      const newField = {
        id: updatedFieldsWithIDs.length + 1,
        value: "",
      };
      setUrlFields([...updatedFieldsWithIDs, newField]);
    }
    if (
      gameSelect &&
      gameSelect.value !== gameSelect &&
      (urlFields.some((element) => element.value !== "") ||
        gameLoop === "time") &&
      expanded !== "panel2"
    ) {
      setExpanded("panel2");
    }
  }, [gameSelect]);

  useEffect(() => {
    if (id) {
      getCampaignsData();
    }
  }, [id]);

  useEffect(() => {
    var defaultResetValues = {};
    defaultResetValues = {
      ...defaultResetValues,
      selectRewardType1: campaignData?.params?.selectRewardType1,
    };
    defaultResetValues = {
      ...defaultResetValues,
      rewardValue: campaignData?.params?.rewardValue,
    };
    defaultResetValues = {
      ...defaultResetValues,
      rewardValue: campaignData?.params?.rewardValue,
    };
    defaultResetValues = {
      ...defaultResetValues,
      campaignStartDate: campaignData?.campaign_start,
    };
    defaultResetValues = {
      ...defaultResetValues,
      campaignEndDate: campaignData?.campaign_end,
    };
    defaultResetValues = {
      ...defaultResetValues,
      Specific_product_URL: campaignData?.params?.productUrl,
    };
    defaultResetValues = {
      ...defaultResetValues,
      numberOfDays: campaignData?.params?.numberOfDays,
    };
    defaultResetValues = {
      ...defaultResetValues,
      email_collection_required: campaignData?.params?.emailCollectionRequired,
    };

    defaultResetValues = {
      ...defaultResetValues,
      rewardExpirationDate:
        campaignData?.params?.rewardExpirationDate ?? selectedDay,
    };
    defaultResetValues = {
      ...defaultResetValues,
      rewardExpirationDay: campaignData?.params?.rewardExpirationDay ?? days,
    };
    defaultResetValues = {
      ...defaultResetValues,
      campaignPeriod: campaignData?.params?.campaignPeriod,
    };
    defaultResetValues = {
      ...defaultResetValues,
      campaignLaunchDate: campaignData?.params?.campaignLaunchDate,
    };

    reset1(defaultResetValues);
  }, [campaignData]);

  useEffect(() => {
    var defaultResetValues = {};
    if (rewardType === "DISCOUNT_PERCENTAGE") {
      defaultResetValues = {
        ...defaultResetValues,
        rewardValue: discountAmount,
      };
    }
    if (rewardType === "DISCOUNT_CURRENCY") {
      defaultResetValues = {
        ...defaultResetValues,
        rewardValue: discountAmount,
      };
    }
    defaultResetValues = {
      ...defaultResetValues,
      selectRewardType1: rewardType,
    };

    reset1(defaultResetValues);
  }, [rewardType, discountAmount, discountAmount]);

  useEffect(() => {
    urlFields.forEach((value, index) => {
      if (urlFields[index].value && urlFields[index].value !== "") {
        register1(`Specific_product_URL[${index}]`);
        setValue1(`Specific_product_URL[${index}]`, urlFields[index].value);
      }
    });
  }, [urlFields, register1, rewardType, discountAmount]);
  // console.log("couponData123", couponData);
  return (
    <>
      <Modal
        show={open}
        onHide={handleCancel}
        backdrop="static"
        centered
        aria-labelledby="example-custom-modal-styling-title"
        className="initial_add_store_modal"
        style={{
          width: "100% !important",
          zIndex: 9999,
        }}
      >
        <Modal.Body>
          <img
            onClick={handleCancel}
            src={Close_button_Colored}
            className="closeIcon "
            alt="close"
          />
          <div
            className="initial_add_store_main"
            style={{ flexDirection: "column" }}
          >
            <UserJourney
              gameSelect={gameSelect.value}
              from="BoostProduct"
              title1="On your e-shop user gets notified about the mini game with a hidden reward."
              title2="The game appears on your e-shop. User plays a game by clicking on it."
              title3="After the game's completion a pop-up with a reward appears."
              couponData={couponData}
            />
          </div>
        </Modal.Body>
      </Modal>
      <HowItWorks
        showHowItWorks={showHowItWorks}
        setShowHowItWorks={setShowHowItWorks}
      />
      <div className="product-design-bg">
        <div style={{ marginBottom: "8px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1 className="campaign-header">CREATE YOUR CAMPAIGN</h1>{" "}
            <div className="status_chip">
              {campaignData?.status && (
                <ActiveChip
                  active={isActive}
                  id={id}
                  status={campaignData?.status}
                />
              )}
            </div>
          </div>
          <div style={{ marginTop: "32px" }}>
            <h6
              style={{
                alignItems: "center",
                fontWeight: "600",
                display: "inline",
                fontSize: "18px",
              }}
            >
              What's your campaign goal?
            </h6>
            <div className="boomio_radio_spacing">
              <RadioButton
                width={"100%"}
                checked={emailCollectionRequired}
                text={"Collect more leads"}
                id={"option2"}
                value={true}
                textTwo={"Collect visitor's emails for your business."}
                onChange={() => {
                  setEmailCollectionRequired(true);
                }}
              />
              <RadioButton
                width={"100%"}
                margin="0px"
                checked={!emailCollectionRequired}
                text={"Boost my products"}
                id={"option2"}
                value={false}
                textTwo={"Get more awareness for your boosted products."}
                onChange={() => {
                  setEmailCollectionRequired(false);
                }}
              />
            </div>
          </div>
          <div>
            <h6
              style={{
                alignItems: "center",
                fontWeight: "600",
                display: "inline",
                fontSize: "18px",
              }}
            >
              What's your campaign name?
            </h6>
            {/* <strong
              style={{
                display: "inline",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => setShowHowItWorks(true)}
            >
              How it works?
            </strong> */}
          </div>
        </div>
        <div
          className="boomio_input_main"
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "30px",
          }}
        >
          <input
            className={` ${
              campaignName ? "boomio_input" : "boomio_input_unfilled"
            }`}
            type="text"
            name={`My campaign name....`}
            placeholder="My campaign name...."
            onChange={(e) => setCampaignName(e.target.value)}
            value={campaignName}
            aria-label="default input example"
            style={{ fontStyle: "italic" }}
          />
        </div>
        <Accordion
          urlFields={urlFields}
          setUrlFields={setUrlFields}
          rewardType={rewardType}
          setRewardType={setRewardType}
          couponData={couponData}
          setCouponData={setCouponData}
          discountAmount={discountAmount}
          setDiscountAmount={setDiscountAmount}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          days={days}
          setDays={setDays}
          dateRange={dateRange}
          setDateRange={setDateRange}
          shopName={shopName}
          shopConnected={shopConnected}
          setShopConnected={setShopConnected}
          gameSelect={gameSelect}
          setGameSelect={setGameSelect}
          gameLoop={gameLoop}
          setGameLoop={setGameLoop}
          firstShopConnected={firstShopConnected}
          campaignId={id}
          expanded={expanded}
          setExpanded={setExpanded}
        />
        <div className="d-sm-flex flex-column justify-content-between">
          <div
            className="mt-2 flex-wrap gap-2 align-items-center pt-4 w-100 mt-3"
            style={{ borderTop: "1px solid rgba(71, 63, 78, 0.2)" }}
          >
            {!id && (
              <div className="w-100 mb-3">
                <p
                  style={{
                    cursor: "pointer !important",
                    alignItems: "center",
                    fontSize: "12px",
                    marginBottom: "8px",
                    color: "#B0AFBE",
                    display: "inline",
                  }}
                >
                  <h3 className="campaign-header" style={{ fontSize: "18px" }}>
                    LAUNCH THE CAMPAIGN AND GET READY FOR A WILD RIDE OF SUPERB
                    RESULTS!
                  </h3>
                  <div
                    style={{
                      marginTop: "20px",
                      color: "#473F4E",
                      fontSize: "14px",
                    }}
                  >
                    <p
                      style={{
                        display: "inline",
                        fontWeight: 600,
                        cursor: "pointer",
                      }}
                      onClick={() => setActivatedBoomio(!activatedBoomio)}
                    >
                      {
                        <img
                          src={activatedBoomio ? checkIcon : checkIcon1}
                          style={{ marginRight: "6px" }}
                        />
                      }
                      Enable Boomio app in Shopify Themes. A step by step guide
                      is{" "}
                    </p>
                    <p
                      onClick={() => window.open("/settings", "_blank")}
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        display: "inline",
                        fontWeight: 600,
                      }}
                    >
                      HERE
                    </p>
                    .{" "}
                  </div>
                </p>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
              className="campaign_buttons"
            >
              <div className="campaign_first_buttons">
                {(!id || campaignData?.status === "draft") && (
                  <Button
                    style={{
                      minWidth: "120px",
                      marginBottom: "12px",
                    }}
                    className="outline_btn_dark me-2 campaign_first_button"
                    onClick={(data) => {
                      handleCampaignData(null, "draft");
                    }}
                  >
                    <span>Save Draft</span>
                  </Button>
                )}
                <Button
                  style={{
                    minWidth: "120px",
                    marginBottom: "12px",
                  }}
                  className="outline_btn_dark me-2 campaign_first_button"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <span>Preview</span>
                </Button>
                {id && campaignData?.status !== "draft" && (
                  <Button
                    style={{ minWidth: "120px", marginBottom: "12px" }}
                    className="outline_btn_dark me-2 campaign_first_button"
                    onClick={(data) => {
                      handleCampaignData(
                        data,
                        isActive ? "active" : "inactive"
                      );
                    }}
                  >
                    <span>Save</span>
                  </Button>
                )}
                {id && campaignData?.status === "active" && (
                  <Button
                    style={{ minWidth: "120px", marginBottom: "12px" }}
                    className="outline_btn_dark me-2 campaign_first_button"
                    onClick={(data) => {
                      handleCampaignData(data, "inactive");
                    }}
                  >
                    <span>Pause</span>
                  </Button>
                )}
              </div>
              {campaignData?.status !== "active" && (
                <Button
                  style={{
                    minWidth: "140px",
                    marginBottom: "12px",
                    maxWidth: "360px",
                  }}
                  disabled={
                    (!activatedBoomio &&
                      campaignData?.status !== "inactive" &&
                      campaignData?.status !== "draft" &&
                      campaignData?.status !== "finished") ||
                    !shopConnected ||
                    (!urlFields.some((element) => element.value !== "") &&
                      gameLoop !== "time") ||
                    !dateRange?.endDate ||
                    !dateRange?.startDate ||
                    ((!selectedDay || selectedDay === 0) &&
                      (!days || days === "" || days === 0)) ||
                    new Date(dateRange?.endDate) < new Date()
                  }
                  className={`${
                    (!activatedBoomio &&
                      campaignData?.status !== "inactive" &&
                      campaignData?.status !== "draft" &&
                      campaignData?.status !== "finished") ||
                    !shopConnected ||
                    (!urlFields.some((element) => element.value !== "") &&
                      gameLoop !== "time") ||
                    !dateRange?.endDate ||
                    !dateRange?.startDate ||
                    ((!selectedDay || selectedDay === 0) &&
                      (!days || days === "" || days === 0)) ||
                    new Date(dateRange?.endDate) < new Date()
                      ? "disabled_button launch_btn"
                      : "outline_btn_dark_campagin launch_btn"
                  }`}
                  onClick={(data) => {
                    if (shopConnected) {
                      const specificProductURLs = urlFields
                        .map((field) => field.value)
                        .filter(Boolean);
                      handleCampaignData(
                        {
                          ...data,
                          Specific_product_URL: specificProductURLs,
                        },
                        "active"
                      );
                    } else {
                      toast.error("Connect your shop, please", {
                        closeOnClick: true,
                        pauseOnHover: true,
                      });
                    }
                  }}
                  type="submit"
                >
                  <span>Launch</span>
                </Button>
              )}
            </div>
            <span className="boomio-gray boomio-small w-100 mt-3">
              &nbsp;*You can cancel this campaign at anytime in “Campaigns”
              section.
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecificProductReward;
