import React from "react";
import eyeIcon from "../../../assets/img/eyeIcon.png";
import activeIcon from "../../../assets/img/ActiveIcon.png";

const ActiveChip = ({ active, id, status }) => {
  return (
    <div
      style={{
        padding: "2px 10px",
        borderRadius: "16px",
        minWidth: "95px",
        backgroundColor: status === "active" ? "#48BB78" : "#757575",
      }}
    >
      <label style={{ fontSize: "14px", color: "white" }}>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </label>
      <img
        src={active && id ? activeIcon : eyeIcon}
        style={{ marginLeft: "8px" }}
      ></img>
    </div>
  );
};

export default ActiveChip;
